import { HTTP } from '../../../core';
import { CustomError } from '../../../core';
import Environment from '../../../environment';

export const initForm = (params = {}, isProtectedWebService = true) => {
    console.log("initForm:params:", params);
    const url = Environment.protectedEformUrl + 'eform';
    return HTTP.postData(url, params, isProtectedWebService);
}

export const getEformByNric = (nric = '', isProtectedWebService = false) => {
    console.log("getEformByNric:", nric);
    const url = Environment.protectedEformUrl + `eform?nricNo=${nric}`
    return HTTP.getData(url, isProtectedWebService);
} 

export const getEformByUuid = (uuid='', isProtectedWebService = true) =>{
    console.log(" getEformByUuid Uuid:",uuid);
    const url = Environment.protectedEformUrl + `eform/${uuid}`;
    return HTTP.getData(url, isProtectedWebService);
}

export const getCrsTINByUuid = (uuid='', isProtectedWebService = true) =>{
    console.log("getCrsTINByUuid Uuid:",uuid);
    const url = Environment.protectedEformUrl + `eform/${uuid}/crs`;
    return HTTP.getData(url, isProtectedWebService);
}

export const createRPAApplication = (formUuid='', isProtectedWebService=true) =>{
    console.log('createRPAApplication:formUuid:',formUuid);
    const url = Environment.protectedEformUrl + `eform/${formUuid}/rpaCreateApplication`;
    return HTTP.postData(url, {}, isProtectedWebService);
} 

export const updateRPAApplication = (formUuid='', isProtectedWebService=true) =>{
    console.log('createRPAApplication:formUuid:',formUuid);
    const url = Environment.protectedEformUrl + `eform/${formUuid}/rpaUpdateApplication`;
    return HTTP.postData(url, {}, isProtectedWebService);
} 

export const updateAipOffEformStatus = (formUuid='', isProtectedWebService=true) =>{
    console.log('updateAipOffEformStatus:formUuid:',formUuid);
    const url = Environment.protectedEformUrl + `eform/${formUuid}/updateAipOffEformStatus`;
    return HTTP.postData(url, {}, isProtectedWebService);
}

export const updateAipOffCompletedApplication = (formUuid='', isProtectedWebService=true) =>{
    console.log('updateAipOffCompletedApplication:formUuid:',formUuid);
    const url = Environment.protectedEformUrl + `eform/${formUuid}/aipOffCompletedApplication`;
    return HTTP.postData(url, {}, isProtectedWebService);
} 

export const updateForm = (eformUuid='', params = {}, isProtectedWebService = true) => {
    console.log("updateForm:params:", eformUuid ,params);
    const url = Environment.protectedEformUrl + `eform/${eformUuid}`;
    return HTTP.patchData(url, params, isProtectedWebService);
}

export const updateCrsTIN = (eformUuid='', params = {}, isProtectedWebService = true) => {
    console.log('updateDeclaration:eformUuid:params:',eformUuid, params)
    const url = Environment.protectedEformUrl + `eform/${eformUuid}/crs`;
    return HTTP.postData(url, params, isProtectedWebService);
}

export const updateCrsTINInBulk = (eformUuid='', tinPayloadArray = [], isProtectedWebService = true) =>{
    console.log('updateCrsTINInBulk:eformUuid:tinPayloadArray:',eformUuid, tinPayloadArray);
    let consolidatedUpdateCrsTinServices = [];
    for(let tin of tinPayloadArray){
        consolidatedUpdateCrsTinServices.push(
            updateCrsTIN(eformUuid, tin, isProtectedWebService)
                .then(resp=>({...tin, hasSent:true}))
                .catch(error=>({...tin, hasSent:false, error:error}))
        )
    }
    return Promise.all(consolidatedUpdateCrsTinServices);
}

export const updateCrsTINInfo = (eformUuid='', params=[], isProtectedWebService = true) =>{
    console.log('updateCrsTINInfo:eformUuid:params:',eformUuid, params);
    const url = Environment.protectedEformUrl + `eform/${eformUuid}/crs`;
    return HTTP.putData(url, params, isProtectedWebService)
}

export const getInitialLoanOffer = (eformUuid='', isProtectedWebService = true) =>{
    console.log('getInitialLoanOffer:eformUuid:',eformUuid);
    const url = Environment.protectedEformUrl + `eform/${eformUuid}/loanDetails`;
    return HTTP.getData(url, isProtectedWebService)
}

export const updateInitialLoanOffer = (eformUuid='', params = {}, isProtectedWebService = true) =>{
    console.log('updateInitialLoanOffer:eformUuid:params:',eformUuid, params);
    const url = Environment.protectedEformUrl + `eform/${eformUuid}/loanDetails`;
    return HTTP.patchData(url, params, isProtectedWebService);
}

export const getSecuriyQuestion = (eformUuid='', isProtectedWebService = true) =>{
    console.log("getSecuriyQuestion:eformUuid:params:", eformUuid);
    const url = Environment.protectedEformUrl + `eform/${eformUuid}/ctos/requestQuestions`;
    return HTTP.getData(url, isProtectedWebService);
}

export const getVerifiedSecurityAnswer = (eformUuid='', params = {}, isProtectedWebService = true) =>{
    console.log("getVerifiedSecurityAnswer:eformUuid:params:", eformUuid,  params);
    const url = Environment.protectedEformUrl + `eform/${eformUuid}/ctos/verifyAnswers`;
    return HTTP.postData(url, params, isProtectedWebService);
}

export const getAmendmentsByEformUuid = (eformUuid = '', isProtectedWebService = true) => {
    console.log('getAmendmentsByEformUuid:', eformUuid);
    const url = Environment.protectedEformUrl + `eform/${eformUuid}/amendment`;
    return HTTP.getData(url, isProtectedWebService);
}

export const updateAmendmentsByEformUuid = (eformUuid = '', params = {}, isProtectedWebService = true) =>{
    console.log('updateAmendmentsByEformUuid:',eformUuid,params);
    const url = Environment.protectedEformUrl + `eform/${eformUuid}/amendment`;
    return HTTP.postData(url, params, isProtectedWebService);
}

export const updateNeedHelpCustomerInfo = (params = {}, isProtectedWebService = false) => {
    console.log("updateNeedHelpCustomerInfo:params:", params);
    const url = Environment.publicEformUrl + 'need_help';
    return HTTP.postData(url, params, isProtectedWebService);
}

export const getAopId = (aopId = '', isProtectedWebService = false) => {
    console.log("getAopId", aopId);
    const url = Environment.publicEformUrl + `aopInfo/${aopId}`;
    return HTTP.getData(url, isProtectedWebService);
}

export const calculateCashBackProtected = (eformUuid, loanAmount, tenure, isProtectedWebService = true) => {
    console.log("calculateCashBackProtected", loanAmount, tenure);
    const url = Environment.protectedEformUrl + `eform/`+ eformUuid + `/calculateCashBack?loanAmount=${loanAmount}&tenure=${tenure}`;
    return HTTP.getData(url, isProtectedWebService);
}