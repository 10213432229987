import React, { Component } from "react";
import SectionTitle from "../../components/SectionTitle/index";
import Section from "../../components/Section";
import { StateList, PloanScreensList } from '../../constant';
import {
    Container, Row, Col, Button, ListGroup, ListGroupItem,
    Collapse, Badge
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';
import {
    moreAboutYourself, employmentDetails, emergencyContact, preferredBank, fatcaCrs, eligibility, initialLoanOffer
} from '../../reduxStore/actions'
import { FormHelper, Webservice, PayloadGenerator, JWT } from '../../services';
import { getStateByPostcode } from '../../services/formHelper';
import MoreAboutYourself from "./moreAboutYourself";
import EmploymentDetails from "./employmentDetails";
import EmergencyContact from "./emergencyContact";
import DisbursementBank from "./disbursementBank";
import Fatca from "./fatca";
import Crs from "./crs";
import Routes from '../../routes';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { withCustomAlert, withRefreshHandler } from '../../hoc';
import BaseComponent from '../../class/baseComponent';
import NeedHelp from "../../components/NeedHelp";
import { isValidSession } from '../../services/session';

class SubmitMoreInfo extends BaseComponent {

    finishedAboutSelf = false;
    finishedEmploymentDetails = false;
    finishedEmergencyContact = false;
    finishedPreferredBank = false;
    finishedFatcaCrs = false;
    finishedCrsTin = false;
    
    constructor(props) {
        super(props);

        const {
            residenceType, lengthOfStay, residenceStatus, purposeOfBorrowing,
            sourceOfFund, sourceOfWealth,
            // otherSourceOfFund, otherSourceOfWealth
        } = this.props.moreAboutYourself;

        const {
            dateJoinedCompany, companyTel, companyEmail
        } = this.props.employmentDetails;

        const {
            emergencyContactName, emergencyContactRelationship, emergencyContactMobile,
            spouseName, spouseCompanyName, spouseMobile
        } = this.props.emergencyContact;

        const {
            preferredBank, bankAccountNumber, isNotJoinAccNo
        } = this.props.preferredBank;

        const {
            fatcaDeclaration, fatcaCountryBirth, taxResidence, tinArray
        } = this.props.fatcaCrs;

        const { company, companyAddress, companyAddress2,
            companyPostcode, companyCity, companyLocalState, companyForeignState,
            companyCountry } = this.props.eligibility;

        this.state = {
            collapse1: true,
            collapse2: false,
            collapse3: false,
            collapse4: false,
            collapse5: false,
            index: 1,

            residenceType, lengthOfStay, residenceStatus, purposeOfBorrowing,
            sourceOfFund, sourceOfWealth, dateJoinedCompany, companyTel, companyEmail,
            companyAddress, companyAddress2, companyPostcode, companyCity, companyLocalState,
            companyForeignState, companyCountry, emergencyContactName,
            emergencyContactRelationship, emergencyContactMobile, spouseName,
            spouseCompanyName, spouseMobile, preferredBank, bankAccountNumber, isNotJoinAccNo,
            fatcaDeclaration, fatcaCountryBirth, taxResidence, tinArray,
            // otherSourceOfFund, otherSourceOfWealth,
            currentEmployer: company
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { isAipOff, loanAmount, interestRate, monthlyInstallment, selectedTenure, installmentAfterCashBack, cashBack, totalCashBack } = this.props.calculator;
        if( isAipOff && this.isComeFromVerifyIdentity) {
            console.log('here')
            this.props.updateInitialLoanOfferStore({
                selectedLoanAmount: loanAmount,
                selectedInterestRate: interestRate,
                selectedMonthlyInstallmentAmount: monthlyInstallment,
                selectedTenure: selectedTenure.value,
                selectedSellingAmount: FormHelper.Calculator.calculateSellingPrice(loanAmount, interestRate, selectedTenure.value),
                cashback: cashBack,
                totalCashback: totalCashBack,
                installmentAfterCashback:installmentAfterCashBack
            });
        }
        console.log(this.props.initialLoanOffer)
    }

    isComeFromVerifyIdentity = () => {
        return this.props.location.fromVerifyIdentity;
      }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            companyCountry, companyPostcode, companyLocalState, taxResidence,
            residenceType, lengthOfStay, residenceStatus, purposeOfBorrowing,
            sourceOfFund, sourceOfWealth, currentEmployer, dateJoinedCompany,
            companyTel, companyEmail, companyAddress, companyAddress2,
            companyCity, companyForeignState, emergencyContactName,
            emergencyContactRelationship, emergencyContactMobile,
            spouseName, spouseCompanyName, spouseMobile, preferredBank, bankAccountNumber,
            isNotJoinAccNo, fatcaDeclaration, fatcaCountryBirth, tinArray
        } = this.state;

        const prevCompanyCountry = prevState.companyCountry;
        const prevCompanyLocalPostCode = prevState.companyPostcode;
        const prevCompanyLocalSelectedState = prevState.companyLocalState;
        const prevTaxResidence = prevState.taxResidence;

        //clear state, postcode, city, foreignState if country changed
        if (JSON.stringify(companyCountry) !== JSON.stringify(prevCompanyCountry)) {
            this.setState({
                companyLocalState: '',
                companyPostcode: '',
                companyCity: '',
                companyForeignState: ''
            })
        }

        //auto populate state if country is Malaysia and is a valid local state code
        if (companyPostcode !== prevCompanyLocalPostCode && companyCountry && companyCountry.value === 'MY'
            && companyPostcode.length === 5 && FormHelper.Validation.isLocalPostcode(companyPostcode)) {
            const stateCode = getStateByPostcode(companyPostcode);
            const stateObj = StateList.find(stateObject => stateObject.value === stateCode)
            this.setState({ companyLocalState: stateObj })
        }

        //clear postcode if state changed and country is malaysia
        if (companyCountry && companyCountry.value === 'MY' &&
            Object.keys(prevCompanyLocalSelectedState).length > 0 &&
            (JSON.stringify(companyLocalState) !== JSON.stringify(prevCompanyLocalSelectedState)) &&
            getStateByPostcode(companyPostcode) !== companyLocalState.value) { //only clear postcode if the postcode does not map with state
            console.log('companyLocalState:', companyLocalState)
            this.setState({ companyPostcode: '' })
        }

        if (JSON.stringify(taxResidence) !== JSON.stringify(prevTaxResidence)) {
            this.setState({
                tinArray: [{
                    taxJurisCountry: '',
                    tinNumber: '',
                    isNoTin: false,
                    reasonOfNoTin: '',
                    noTinOtherReason: ''
                }]
            });
        }

        if (
            JSON.stringify(residenceType) !== JSON.stringify(prevState.residenceType) ||
            JSON.stringify(lengthOfStay) !== JSON.stringify(prevState.lengthOfStay) ||
            JSON.stringify(residenceStatus) !== JSON.stringify(prevState.residenceStatus) ||
            JSON.stringify(purposeOfBorrowing) !== JSON.stringify(prevState.purposeOfBorrowing) ||
            JSON.stringify(sourceOfFund) !== JSON.stringify(prevState.sourceOfFund) ||
            JSON.stringify(sourceOfWealth) !== JSON.stringify(prevState.sourceOfWealth)
            // JSON.stringify(this.state.otherSourceOfFund) !== JSON.stringify(prevState.otherSourceOfFund) ||
            // JSON.stringify(this.state.otherSourceOfWealth) !== JSON.stringify(prevState.otherSourceOfWealth)
        ) {
            this.finishedAboutSelf = false;
        }

        if (
            JSON.stringify(currentEmployer) !== JSON.stringify(prevState.currentEmployer) ||
            JSON.stringify(dateJoinedCompany) !== JSON.stringify(prevState.dateJoinedCompany) ||
            JSON.stringify(companyTel) !== JSON.stringify(prevState.companyTel) ||
            JSON.stringify(companyEmail) !== JSON.stringify(prevState.companyEmail) ||
            JSON.stringify(companyAddress) !== JSON.stringify(prevState.companyAddress) ||
            JSON.stringify(companyAddress2) !== JSON.stringify(prevState.companyAddress2) ||
            JSON.stringify(companyPostcode) !== JSON.stringify(prevState.companyPostcode) ||
            JSON.stringify(companyCity) !== JSON.stringify(prevState.companyCity) ||
            JSON.stringify(companyLocalState) !== JSON.stringify(prevState.companyLocalState) ||
            JSON.stringify(companyForeignState) !== JSON.stringify(prevState.companyForeignState) ||
            JSON.stringify(companyCountry) !== JSON.stringify(prevState.companyCountry)
        ) {
            this.finishedEmploymentDetails = false;
        }

        if (
            JSON.stringify(emergencyContactName) !== JSON.stringify(prevState.emergencyContactName) ||
            JSON.stringify(emergencyContactRelationship) !== JSON.stringify(prevState.emergencyContactRelationship) ||
            JSON.stringify(emergencyContactMobile) !== JSON.stringify(prevState.emergencyContactMobile) ||
            JSON.stringify(spouseName) !== JSON.stringify(prevState.spouseName) ||
            JSON.stringify(spouseCompanyName) !== JSON.stringify(prevState.spouseCompanyName) ||
            JSON.stringify(spouseMobile) !== JSON.stringify(prevState.spouseMobile)
        ) {
            this.finishedEmergencyContact = false;
        }

        if (
            JSON.stringify(preferredBank) !== JSON.stringify(prevState.preferredBank) ||
            JSON.stringify(bankAccountNumber) !== JSON.stringify(prevState.bankAccountNumber) ||
            JSON.stringify(isNotJoinAccNo) !== JSON.stringify(prevState.isNotJoinAccNo)
        ) {
            this.finishedPreferredBank = false;
        }

        if (
            JSON.stringify(fatcaDeclaration) !== JSON.stringify(prevState.fatcaDeclaration) ||
            JSON.stringify(fatcaCountryBirth) !== JSON.stringify(prevState.fatcaCountryBirth) ||
            JSON.stringify(taxResidence) !== JSON.stringify(prevState.taxResidence)
        ) {
            this.finishedFatcaCrs = false;
        }

        if (JSON.stringify(tinArray) !== JSON.stringify(prevState.tinArray)) {
            this.finishedCrsTin = false;
        }

    }

    onResidenceTypeChange = (residenceType) => {
        this.setState({ residenceType })
    }

    onLengthOfStayChange = (lengthOfStay) => {
        this.setState({ lengthOfStay })
    }

    onResidenceStatusChange = (residenceStatus) => {
        this.setState({ residenceStatus })
    }

    onPurposeOfBorrowingChange = (purposeOfBorrowing) => {
        this.setState({ purposeOfBorrowing })
    }

    onSourceOfFundChange = (sourceOfFund) => {
        this.setState({ sourceOfFund })
    }

    onSourceOfWealthChange = (sourceOfWealth) => {
        this.setState({ sourceOfWealth })
    }

    // onOtherSourceOfFundChange = (otherSourceOfFund) => {
    //     this.setState({ otherSourceOfFund })
    // }

    // onOtherSourceOfWealthChange = (otherSourceOfWealth) => {
    //     this.setState({ otherSourceOfWealth })
    // }

    onDateJoinedCompanyChange = (dateJoinedCompany) => {
        this.setState({ dateJoinedCompany })
        this.isValidMonthDifference(dateJoinedCompany)
    }

    onCompanyTelChange = (companyTel) => {
        companyTel = FormHelper.phoneSeparatorForCompanyTel(companyTel);
        this.setState({ companyTel })
    }

    onCompanyEmailChange = (companyEmail) => {
        this.setState({ companyEmail })
    }

    onCompanyAddressChange = (companyAddress) => {
        this.setState({ companyAddress })
    }

    onCompanyAddress2Change = (companyAddress2) => {
        this.setState({ companyAddress2 })
    }

    onCompanyPostcodeChange = (companyPostcode) => {
        this.setState({ companyPostcode })
    }

    onCompanyCityChange = (companyCity) => {
        this.setState({ companyCity })
    }

    onCompanyLocalStateChange = (companyLocalState) => {
        this.setState({ companyLocalState })
    }

    onCompanyForeignStateChange = (companyForeignState) => {
        this.setState({ companyForeignState })
    }

    onCompanyCountryChange = (companyCountry) => {
        this.setState({ companyCountry })
    }

    onEmergencyContactNameChange = (emergencyContactName) => {
        this.setState({ emergencyContactName })
    }

    onEmergencyContactRelationshipChange = (emergencyContactRelationship) => {
        this.setState({ emergencyContactRelationship })
    }

    onEmergencyContactMobileChange = (emergencyContactMobile) => {
        emergencyContactMobile = FormHelper.phoneSeparator(emergencyContactMobile);
        this.setState({ emergencyContactMobile })
    }

    onSpouseNameChange = (spouseName) => {
        this.setState({ spouseName })
    }

    onSpouseCompanyNameChange = (spouseCompanyName) => {
        this.setState({ spouseCompanyName })
    }

    onSpouseMobileChange = (spouseMobile) => {
        spouseMobile = FormHelper.phoneSeparator(spouseMobile);
        this.setState({ spouseMobile })
    }

    onPreferredBankChange = (preferredBank) => {
        this.setState({ preferredBank })
    }

    onBankAccountNumberChange = (bankAccountNumber) => {
        this.setState({ bankAccountNumber })
    }

    onDeclareJoinAccChange = () => {
        this.setState(prevState => ({ isNotJoinAccNo: !prevState.isNotJoinAccNo }))
    }

    onFatcaDeclarationChange = (fatcaDeclaration) => {
        this.setState({ fatcaDeclaration })
    }

    onFatcaCountryBirthChange = (fatcaCountryBirth) => {
        this.setState({ fatcaCountryBirth })
    }

    onTaxResidenceChange = (taxResidence) => {
        this.setState({ taxResidence })
    }

    renderArrayOfTIN = () => {
        return this.state.tinArray.map((tinObject, i) => {
            return this.renderTIN(tinObject, i);
        })
    }

    onAddMoreTinPressed = () => {
        this.setState((previousState) => {
            let dummy = previousState.tinArray.concat({
                taxJurisCountry: '',
                tinNumber: '',
                isNoTin: false,
                reasonOfNoTin: '',
                noTinOtherReason: ''
            });
            return { tinArray: dummy }
        })
    }

    onRemoveTinPressed = (i) => {
        const newTinArray = this.state.tinArray.filter((tinObject, index) => {
            if (index !== i) return tinObject;
        });

        this.setState({ tinArray: newTinArray });
    }

    onNoTinPressed = (i) => {
        const newTinArray = this.state.tinArray.map((tinObject, index) => {
            if (index !== i) return tinObject;
            return {
                ...tinObject,
                isNoTin: !tinObject.isNoTin,
                tinNumber: '',
                reasonOfNoTin: '',
                noTinOtherReason: ''
            };
        });

        this.setState({ tinArray: newTinArray });
    }

    onTaxJurisCountryChange = (itemValue, i) => {
        if (itemValue == null && itemValue == undefined) {
            return;
        }
        const newTinArray = this.state.tinArray.map((tinObject, index) => {
            if (index !== i) return tinObject;
            return { ...tinObject, taxJurisCountry: itemValue };
        });

        this.setState({ tinArray: newTinArray });
    }

    onTinNumberChange = (result, i) => {
        const newTinArray = this.state.tinArray.map((tinObject, index) => {
            if (index !== i) return tinObject;
            return { ...tinObject, tinNumber: result };
        });

        this.setState({ tinArray: newTinArray });
    }

    onReasonOfNoTinChange = (itemValue, i) => {
        const newTinArray = this.state.tinArray.map((tinObject, index) => {
            if (index !== i) return tinObject;
            return { ...tinObject, reasonOfNoTin: itemValue };
        });

        this.setState({ tinArray: newTinArray });
    }

    onNoTinOtherReasonChange = (result, i) => {
        const newTinArray = this.state.tinArray.map((tinObject, index) => {
            if (index !== i) return tinObject;
            return { ...tinObject, noTinOtherReason: result };
        });

        this.setState({ tinArray: newTinArray });
    }

    toggleTabIcon = index => {
        if (this.state[`collapse${index}`]) {
            return <FontAwesomeIcon className="mr-2 mt-4" icon={faMinus} />;
        } else {
            return <FontAwesomeIcon className="mr-2 mt-4" icon={faPlus} />;
        }
    };

    toggleSubmitMoreInfo = e => {
        const index = e.currentTarget.dataset.index;
        this.setState({ [`collapse${index}`]: !this.state[`collapse${index}`] });
    };

    toggleStatus = index => {
        let status = "";
        let className = "";
        if (this.state.index < index) {
            status = "NOT COMPLETED";
            className = "badge-not-completed";
        } else if (this.state.index === index) {
            status = "IN PROGRESS";
            className = "badge-in-progress";
        } else {
            status = "COMPLETED";
            className = "badge-completed";
        }
        return <Badge className={`p-2 ml-4 ${className}`}>{status}</Badge>;
    };

    getPostCodeValidation = () => {
        const { companyCountry } = this.state;

        if (companyCountry && companyCountry.value === 'MY')
            return FormHelper.Validation.isLocalPostcode;
        else
            return FormHelper.Validation.isForeignPostcode;
    }

    isValidPostCodeAccordingToCountry = () => {
        const { companyCountry, companyPostcode } = this.state;

        if (companyCountry && companyCountry.value === 'MY' && !FormHelper.Validation.isLocalPostcode(companyPostcode))
            return false;
        return true;
    }

    isPostCodeOptional = () => {
        const { companyCountry } = this.state;
        if (companyCountry && companyCountry.value === 'MY')
            return false;
        return true;
    }

    isStateOptional = () => {
        const { companyCountry } = this.state;
        if (companyCountry && companyCountry.value === 'MY')
            return false;
        return true;
    }

    isMarried = () => {
        const { maritalStatus } = this.props.eligibility;
        if (maritalStatus.value === 'M')
            return true;
        return false;
    }

    isNonMalaysianTaxResident = () => {
        const { taxResidence } = this.state;
        if (taxResidence && (taxResidence.value === 'MNTR' || taxResidence.value === 'NTR'))
            return true;
        return false;
    }

    // isOtherSourceOfFund = () => {
    //     const { sourceOfFund } = this.state;
    //     if (sourceOfFund.value === 'F20')
    //         return true;
    //     return false;
    // }

    // isOtherSourceOfWealth = () => {
    //     const { sourceOfWealth } = this.state;
    //     if (sourceOfWealth.value === 'F20')
    //         return true;
    //     return false;
    // }

    isNotUSPerson = () => {
        const { fatcaDeclaration } = this.state;
        if (fatcaDeclaration && fatcaDeclaration.value === false)
            return true;
        return false;
    }

    isEmptyTinArray = () => {
        const { tinArray } = this.state;

        if (tinArray.length < 0)
            return true;

        if (!tinArray[0].taxJurisCountry)
            return true;

        if (!tinArray[0].taxJurisCountry.value)
            return true;

        return false;
    }

    isValidTinArray = () => {
        const { tinArray, taxResidence } = this.state;
        const { isOnlyAlphanumeric } = FormHelper.Validation;

        if (!taxResidence) {
            return false;
        }
        if (this.isNonMalaysianTaxResident()) {
            try {
                tinArray.map((tinObject, index) => {
                    if (!tinObject.taxJurisCountry) {
                        throw new Error();
                    }
                    if (!tinObject.isNoTin && (!tinObject.tinNumber || !isOnlyAlphanumeric(tinObject.tinNumber))) {
                        throw new Error();
                    }
                    if (tinObject.isNoTin && !tinObject.reasonOfNoTin) {
                        throw new Error();
                    }
                    if (tinObject.reasonOfNoTin && tinObject.reasonOfNoTin.value === "3" && !tinObject.noTinOtherReason) {
                        throw new Error();
                    }
                })
            } catch (error) {
                return false;
            }
        }
        return true;
    }

    isValidMonthDifference = (dateJoinedCompany) => {
        const { selectedEmploymentType } = this.props.calculator;
        const dt1 = dateJoinedCompany;
        const dt2 = new Date();
        const isBusiness = selectedEmploymentType && (selectedEmploymentType.value == 'SESB' || selectedEmploymentType.value == 'SESP') // min 24 months
        const isEmployment = selectedEmploymentType && (selectedEmploymentType.value == 'GS' || selectedEmploymentType.value == 'PE' || selectedEmploymentType.value == 'CE') // min 1 month
        const validBusinessDiffMonths = dt1 && isBusiness && (FormHelper.Validation.diff_months(dt2, dt1) >= 24)
        const validEmploymentDiffMonths = dt1 && isEmployment && (FormHelper.Validation.diff_months(dt2, dt1) >= 1)
        if (validBusinessDiffMonths || validEmploymentDiffMonths)
            return true;
        return false;
    }

    hasFilledAllAboutYourself = () => {
        const {
            residenceType, lengthOfStay, residenceStatus, purposeOfBorrowing,
            sourceOfFund, sourceOfWealth, otherSourceOfFund, otherSourceOfWealth
        } = this.state;

        let hasFilledAll = residenceType && lengthOfStay && sourceOfFund && sourceOfWealth;

        // if (this.isOtherSourceOfFund())
        //     hasFilledAll = hasFilledAll && otherSourceOfFund

        // if (this.isOtherSourceOfWealth())
        //     hasFilledAll = hasFilledAll && otherSourceOfWealth

        return hasFilledAll;
    }

    hasFilledAllEmploymentDetails = () => {
        const {
            currentEmployer, dateJoinedCompany, companyTel, companyEmail, companyAddress,
            companyPostcode, companyCity, companyLocalState, companyCountry
        } = this.state;

        let hasFilledAll = currentEmployer && dateJoinedCompany && companyTel && companyEmail
            && companyAddress && companyCity && companyCountry

        if (!this.isPostCodeOptional())
            hasFilledAll = hasFilledAll && companyPostcode;

        if (!this.isStateOptional())
            hasFilledAll = hasFilledAll && companyLocalState;

        return hasFilledAll;
    }

    hasFilledAllEmergencyContact = () => {
        const {
            emergencyContactName, emergencyContactRelationship, emergencyContactMobile,
            spouseName, spouseMobile
        } = this.state;

        let hasFilledAll = emergencyContactName && emergencyContactRelationship
            && emergencyContactMobile

        // if (this.isMarried())
        //     hasFilledAll = hasFilledAll && spouseName && spouseMobile;

        return hasFilledAll;
    }

    hasFilledAllPreferredBank = () => {
        const {
            preferredBank, bankAccountNumber, isNotJoinAccNo
        } = this.state;

        let hasFilledAll = preferredBank && bankAccountNumber && isNotJoinAccNo

        return hasFilledAll;
    }

    hasFilledAllFatca = () => {
        const {
            fatcaDeclaration, fatcaCountryBirth
        } = this.state;

        let hasFilledAll;

        if (this.isNotUSPerson() && fatcaCountryBirth && !FormHelper.isUSPerson(fatcaCountryBirth.value))
            hasFilledAll = fatcaDeclaration && fatcaCountryBirth

        return hasFilledAll;
    }

    hasFilledAllCrs = () => {
        const {
            taxResidence, tinArray
        } = this.state;

        let hasFilledAll;

        if (this.isValidTinArray()) {
            hasFilledAll = taxResidence && tinArray
        }

        return hasFilledAll;
    }

    isValidEmploymentDetails = () => {

        const {
            isEmailFormat, isOnlyAlphabetAndSpace, isValidAddress, isValidCompanyTel
        } = FormHelper.Validation;

        const { companyEmail, companyCity, companyTel, companyAddress, companyAddress2, dateJoinedCompany } = this.state;

        let isValidForm = this.hasFilledAllEmploymentDetails()
            && this.isValidPostCodeAccordingToCountry() && isEmailFormat(companyEmail)
            && isOnlyAlphabetAndSpace(companyCity) && isValidCompanyTel(companyTel)
            && isValidAddress(companyAddress) && isValidAddress(companyAddress2)
            && this.isValidMonthDifference(dateJoinedCompany)

        return isValidForm;
    }

    isValidEmergencyContact = () => {

        const {
            isValidName, isValidLocalMobileNumber
        } = FormHelper.Validation;

        const {
            emergencyContactName, emergencyContactMobile, spouseName, spouseMobile
        } = this.state;

        let isValidForm = this.hasFilledAllEmergencyContact() && isValidName(emergencyContactName)
            && isValidLocalMobileNumber(emergencyContactMobile)

        // if (this.isMarried()) {
        //     isValidForm = isValidForm && isValidName(spouseName)
        //         && isValidLocalMobileNumber(spouseMobile)
        // }

        return isValidForm;
    }

    isValidPreferredBank = () => {

        const { isOnlyNumber } = FormHelper.Validation;
        const { bankAccountNumber } = this.state;

        let isValidForm = this.hasFilledAllPreferredBank() && isOnlyNumber(bankAccountNumber)

        return isValidForm;
    }

    isValidFatcaCrs = () => {
        let isValidForm = this.hasFilledAllFatca()
        // && this.hasFilledAllCrs();
        return isValidForm;
    }

    enableCollapseTabAfterCompleted = (e) => {
        const index = e.currentTarget.dataset.index;
        if (index == this.state.index) {
            this.setState({
                index: this.state.index + 1,
                [`collapse${this.state.index + 1}`]: !this.state[
                    `collapse${this.state.index + 1}`
                ],
                [`collapse${this.state.index}`]: !this.state[
                    `collapse${this.state.index}`
                ]
            });
        } else {
            this.setState({
                [`collapse${index}`]: !this.state[`collapse${index}`]
            });
        }
    };

    onAboutYourselfContinuePressed = async (e) => {
        const {
            residenceType, lengthOfStay, residenceStatus, purposeOfBorrowing,
            sourceOfFund, sourceOfWealth,
            // otherSourceOfFund, otherSourceOfWealth
        } = this.state;

        this.props.updateMoreAboutYourselfStore({
            residenceType, lengthOfStay,
            sourceOfFund, sourceOfWealth,
            residenceStatus: {
                value: "0",
                label: "I am a Malaysian residing in Malaysia"
            },
            purposeOfBorrowing: {
                value: "PU",
                label: "Personal Use"
            }

            // otherSourceOfFund, otherSourceOfWealth
        })

        this.updateAboutYourselfPayload();
        if (this.props.calculator.isAipOff) {
            this.updateSellingPriceForAipOff();
        }

        this.enableCollapseTabAfterCompleted(e);
    }

    onEmploymentDetailsContinuePressed = async (e) => {
        const {
            currentEmployer, dateJoinedCompany, companyTel, companyEmail, companyAddress,
            companyAddress2, companyPostcode, companyCity, companyLocalState,
            companyForeignState, companyCountry
        } = this.state;

        this.props.updateEmploymentDetailsStore({
            currentEmployer, dateJoinedCompany, companyTel, companyEmail
        })

        this.props.updateEligibilityStore({
            companyAddress, companyAddress2, companyPostcode, companyCity, companyLocalState,
            companyForeignState, companyCountry
        })

        this.updateEmploymentDetailsPayload();

        this.enableCollapseTabAfterCompleted(e);
    }

    onEmergencyContactContinuePressed = async (e) => {
        const {
            emergencyContactName, emergencyContactRelationship, emergencyContactMobile,
            spouseName, spouseCompanyName, spouseMobile
        } = this.state;

        this.props.updateEmergencyContactStore({
            emergencyContactName, emergencyContactRelationship, emergencyContactMobile,
            spouseName, spouseCompanyName, spouseMobile
        })

        this.updateEmergencyContactPayload();

        this.enableCollapseTabAfterCompleted(e);
    }

    onPreferredBankContinuePressed = async (e) => {
        const {
            preferredBank, bankAccountNumber, isNotJoinAccNo
        } = this.state;

        this.props.updatePreferredBankStore({
            preferredBank, bankAccountNumber, isNotJoinAccNo
        })

        this.updatePreferredBankPayload();

        this.enableCollapseTabAfterCompleted(e);
    }

    onFatcaCrsContinuePressed = async (e) => {
        const {
            fatcaDeclaration, fatcaCountryBirth, taxResidence, tinArray
        } = this.state;

        this.props.updateFatcaCrsStore({
            fatcaDeclaration, fatcaCountryBirth, taxResidence, tinArray
        })

        this.updateFatcaCrsPayload();

        // this.updateCrsTinPayload();

        this.enableCollapseTabAfterCompleted(e);
    }

    onFooterProceedButtonPressed = async () => {
        const {
            residenceType, lengthOfStay, residenceStatus, purposeOfBorrowing,
            sourceOfFund, sourceOfWealth,
            // otherSourceOfFund, otherSourceOfWealth,

            currentEmployer, dateJoinedCompany, companyTel, companyEmail, companyAddress,
            companyAddress2, companyPostcode, companyCity, companyLocalState,
            companyForeignState, companyCountry,

            emergencyContactName, emergencyContactRelationship, emergencyContactMobile,
            spouseName, spouseCompanyName, spouseMobile,

            preferredBank, bankAccountNumber, isNotJoinAccNo,

            fatcaDeclaration, fatcaCountryBirth, taxResidence, tinArray
        } = this.state;

        if (!this.finishedAboutSelf) {

            this.props.updateMoreAboutYourselfStore({
                residenceType, lengthOfStay,
                sourceOfFund, sourceOfWealth,
                residenceStatus: {
                    value: "0",
                    label: "I am a Malaysian residing in Malaysia"
                },
                purposeOfBorrowing: {
                    value: "PU",
                    label: "Personal Use"
                }
                // otherSourceOfFund, otherSourceOfWealth
            })

            if (! await this.updateAboutYourselfPayload())
                return;

            if(this.props.calculator.isAipOff) {
                if (! await this.updateSellingPriceForAipOff())
                return;
            }

        }
        if (!this.finishedEmploymentDetails) {

            this.props.updateEmploymentDetailsStore({
                currentEmployer, dateJoinedCompany, companyTel, companyEmail
            })

            this.props.updateEligibilityStore({
                companyAddress, companyAddress2, companyPostcode, companyCity, companyLocalState,
                companyForeignState, companyCountry
            })

            if (! await this.updateEmploymentDetailsPayload())
                return;
        }
        if (!this.finishedEmergencyContact) {

            this.props.updateEmergencyContactStore({
                emergencyContactName, emergencyContactRelationship, emergencyContactMobile,
                spouseName, spouseCompanyName, spouseMobile
            })

            if (! await this.updateEmergencyContactPayload())
                return;
        }
        if (!this.finishedPreferredBank) {

            this.props.updatePreferredBankStore({
                preferredBank, bankAccountNumber, isNotJoinAccNo
            })

            if (! await this.updatePreferredBankPayload())
                return;
        }
        if (!this.finishedFatcaCrs) {

            this.props.updateFatcaCrsStore({
                fatcaDeclaration, fatcaCountryBirth, taxResidence
            })

            if (! await this.updateFatcaCrsPayload())
                return;
        }

        // if (!this.finishedCrsTin) {

        //     this.props.updateFatcaCrsStore({ tinArray })

        //     if (!await this.updateCrsTinPayload())
        //         return;
        // }

        this.props.history.push(Routes.receiveMoney)
    }

    updateSellingPriceForAipOff = async () => {
        try {
            let params = PayloadGenerator.genUpdateInitialLoanOfferPayload();
            console.log('params', params);
            let newEformUuid = this.props.webserviceConfig.eformUuid
            console.log("updateSellingPriceForAipOff::", params)

            await Webservice.Eform.updateForm(newEformUuid, params).then(resp => {
                console.log('updateInitialLoanOffer:success:', resp)
            })
        } catch (error) {
            console.log('updateSellingPriceForAipOff:err:', error)
            this.baseErrorHandler(error);
            return false;
        }
        return true;
    }

    updateAboutYourselfPayload = async () => {
        try {
            let params = PayloadGenerator.genUpdateAboutYourselfPayload();
            let newEformUuid = this.props.webserviceConfig.eformUuid

            await Webservice.Eform.updateForm(newEformUuid, params).then(resp => {
                console.log('updateEform:success:', resp)
                this.finishedAboutSelf = true;
            })
        } catch (error) {
            console.log('updateEform:err:', error)
            this.baseErrorHandler(error);
            this.finishedAboutSelf = false;
            return false;
        }
        return true;
    }

    updateEmploymentDetailsPayload = async () => {
        try {
            let params = PayloadGenerator.genUpdateEmploymentDetailsPayload();
            let newEformUuid = this.props.webserviceConfig.eformUuid

            await Webservice.Eform.updateForm(newEformUuid, params).then(resp => {
                console.log('updateEform:success:', resp)
                this.finishedEmploymentDetails = true;
            })
        } catch (error) {
            console.log('updateEform:err:', error)
            this.baseErrorHandler(error);
            this.finishedEmploymentDetails = false;
            return false;
        }
        return true;
    }

    updateEmergencyContactPayload = async () => {
        try {
            let params = PayloadGenerator.genUpdateEmergencyContactPayload();
            let newEformUuid = this.props.webserviceConfig.eformUuid

            await Webservice.Eform.updateForm(newEformUuid, params).then(resp => {
                console.log('updateEform:success:', resp)
                this.finishedEmergencyContact = true;
            })
        } catch (error) {
            console.log('updateEform:err:', error)
            this.baseErrorHandler(error);
            this.finishedEmergencyContact = false;
            return false;
        }
        return true;
    }

    updatePreferredBankPayload = async () => {
        try {
            let params = PayloadGenerator.genUpdatePreferredBankPayload();
            let newEformUuid = this.props.webserviceConfig.eformUuid

            await Webservice.Eform.updateForm(newEformUuid, params).then(resp => {
                console.log('updateEform:success:', resp)
                this.finishedPreferredBank = true;
            })
        } catch (error) {
            console.log('updateEform:err:', error)
            this.baseErrorHandler(error);
            this.finishedPreferredBank = false;
            return false;
        }
        return true;
    }

    updateFatcaCrsPayload = async () => {
        try {
            let params = PayloadGenerator.genUpdateFatcaCrsPayload();
            let newEformUuid = this.props.webserviceConfig.eformUuid

            await Webservice.Eform.updateForm(newEformUuid, params).then(resp => {
                console.log('updateEform:success:', resp)
                this.finishedFatcaCrs = true;
            })
        } catch (error) {
            console.log('updateEform:err:', error)
            this.baseErrorHandler(error);
            this.finishedFatcaCrs = false;
            return false;
        }
        return true;
    }

    updateCrsTinPayload = async () => {
        try {
            let { tinArray } = this.state;
            let { eformUuid } = this.props.webserviceConfig;

            console.log('tinArray:', tinArray)

            let tinArrayPayload = this.isEmptyTinArray() ? [] : tinArray.map(item => PayloadGenerator.genCrsTinPayload(item))
            console.log('tinArrayPayload:', tinArrayPayload);

            await Webservice.Eform.updateCrsTINInfo(eformUuid, tinArrayPayload)
                .then(resp => {
                    console.log('updateCrsTinPayload:resp:', resp)
                    this.finishedCrsTin = true;
                }).catch(error => {
                    console.log('Error:updateCrsTINInBulk:', error)
                    throw error;
                })
        } catch (error) {
            this.finishedCrsTin = false;
            this.baseErrorHandler(error)
            return false;
        }

        return true;
    }

    renderFooterProceedButton = index => {
        if (index == 6) {
            if (
                this.hasFilledAllAboutYourself() && this.isValidEmploymentDetails() &&
                this.isValidEmergencyContact() && this.isValidPreferredBank() &&
                this.isValidFatcaCrs()
            ) {
                return (
                    <Section className="proceed-button-footer">
                        <Container>
                            <Row className="mt-5">
                                <Col className="mt-5">
                                    <div className="proceed-button-wrapper">
                                        <Button className="proceedButton back-button">BACK</Button>
                                        <Button id="gtm-submit-more-info" className="proceedButton" onClick={this.onFooterProceedButtonPressed}>
                                            CONTINUE{" "}
                                            <img
                                                alt="arrow right"
                                                src={require("../../assets/img/arrow-right.png")}
                                            />
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Section>
                );
            }
        }
    };

    render() {
        return (
            <>
                <Header
                    version="b"
                    currentStage={4}
                    progressBarPercentage={67}
                />
                <Section className="submit-more-info-section">
                    <Container>
                        <Row>
                            <Col>
                                <SectionTitle text={`Submit Your Full Information, ${this.props.eligibility.name}`} />
                                <p className="section-description">
                                    We require more information from you as part of the final approval process.
                                    This will only take 5 minutes of your time. Please ensure that you have:-
                                </p>
                                <ul className="section-info-description-list">
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} />
                                        Softcopy of your {this.props.calculator.selectedDoc.label}
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} />
                                        Softcopy of your MyKad front and back.
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        <Row className="mb-3 ">
                            <Col className="mb-4">
                                <ListGroup className="submit-more-info-form-list">
                                    <ListGroupItem className="justify-content-between">
                                        <div
                                            className="submit-more-info-form-label"
                                            data-index="1"
                                            onClick={this.hasFilledAllAboutYourself() ? this.toggleSubmitMoreInfo : null}
                                        >
                                            {this.toggleTabIcon(1)} Tell us more about yourself {this.toggleStatus(1)}
                                        </div>
                                        <Collapse className="ml-4 mb-responsive-collapse" isOpen={this.state.collapse1}>

                                            <MoreAboutYourself
                                                residenceType={this.state.residenceType}
                                                onResidenceTypeChange={this.onResidenceTypeChange}
                                                lengthOfStay={this.state.lengthOfStay}
                                                onLengthOfStayChange={this.onLengthOfStayChange}
                                                residenceStatus={this.state.residenceStatus}
                                                onResidenceStatusChange={this.onResidenceStatusChange}
                                                purposeOfBorrowing={this.state.purposeOfBorrowing}
                                                onPurposeOfBorrowingChange={this.onPurposeOfBorrowingChange}
                                                sourceOfFund={this.state.sourceOfFund}
                                                onSourceOfFundChange={this.onSourceOfFundChange}
                                                sourceOfWealth={this.state.sourceOfWealth}
                                                onSourceOfWealthChange={this.onSourceOfWealthChange}
                                                hasFilledAllAboutYourself={this.hasFilledAllAboutYourself()}
                                                onAboutYourselfContinuePressed={this.onAboutYourselfContinuePressed}
                                            />
                                        </Collapse>
                                    </ListGroupItem>
                                    <ListGroupItem className="justify-content-between">
                                        <div
                                            className="submit-more-info-form-label"
                                            data-index="2"
                                            onClick={this.isValidEmploymentDetails() ? this.toggleSubmitMoreInfo : null}
                                        >
                                            {this.toggleTabIcon(2)} Share your employment details {this.toggleStatus(2)}
                                        </div>
                                        <Collapse className="ml-4 mb-responsive-collapse" isOpen={this.state.collapse2}>

                                            <EmploymentDetails
                                                companyAddressVersion={this.props.eligibility.companyAddressVersion}
                                                currentEmployer={this.state.currentEmployer}
                                                dateJoinedCompany={this.state.dateJoinedCompany}
                                                onDateJoinedCompanyChange={this.onDateJoinedCompanyChange}
                                                selectedEmploymentType={this.props.calculator.selectedEmploymentType}
                                                isValidMonthDifference={this.state.dateJoinedCompany && this.isValidMonthDifference(this.state.dateJoinedCompany)}
                                                companyTel={this.state.companyTel}
                                                onCompanyTelChange={this.onCompanyTelChange}
                                                companyEmail={this.state.companyEmail}
                                                onCompanyEmailChange={this.onCompanyEmailChange}
                                                companyAddress={this.state.companyAddress}
                                                onCompanyAddressChange={this.onCompanyAddressChange}
                                                companyAddress2={this.state.companyAddress2}
                                                onCompanyAddress2Change={this.onCompanyAddress2Change}
                                                companyPostcode={this.state.companyPostcode}
                                                onCompanyPostcodeChange={this.onCompanyPostcodeChange}
                                                getPostCodeValidation={this.getPostCodeValidation()}
                                                isPostCodeOptional={this.isPostCodeOptional()}
                                                companyCity={this.state.companyCity}
                                                onCompanyCityChange={this.onCompanyCityChange}
                                                companyCountry={this.state.companyCountry}
                                                onCompanyCountryChange={this.onCompanyCountryChange}
                                                companyLocalState={this.state.companyLocalState}
                                                onCompanyLocalStateChange={this.onCompanyLocalStateChange}
                                                companyForeignState={this.state.companyForeignState}
                                                onCompanyForeignStateChange={this.onCompanyForeignStateChange}
                                                isValidEmploymentDetails={this.isValidEmploymentDetails()}
                                                onEmploymentDetailsContinuePressed={this.onEmploymentDetailsContinuePressed}
                                            />
                                        </Collapse>
                                    </ListGroupItem>
                                    <ListGroupItem className="justify-content-between">
                                        <div
                                            className="submit-more-info-form-label"
                                            data-index="3"
                                            onClick={this.isValidEmergencyContact() ? this.toggleSubmitMoreInfo : null}
                                        >
                                            {this.toggleTabIcon(3)} Share your emergency contact {this.toggleStatus(3)}
                                        </div>
                                        <Collapse className="ml-4 mb-responsive-collapse" isOpen={this.state.collapse3}>

                                            <EmergencyContact
                                                emergencyContactName={this.state.emergencyContactName}
                                                onEmergencyContactNameChange={this.onEmergencyContactNameChange}
                                                emergencyContactRelationship={this.state.emergencyContactRelationship}
                                                onEmergencyContactRelationshipChange={this.onEmergencyContactRelationshipChange}
                                                emergencyContactMobile={this.state.emergencyContactMobile}
                                                onEmergencyContactMobileChange={this.onEmergencyContactMobileChange}
                                                isMarried={this.isMarried()}
                                                spouseName={this.state.spouseName}
                                                onSpouseNameChange={this.onSpouseNameChange}
                                                spouseCompanyName={this.state.spouseCompanyName}
                                                onSpouseCompanyNameChange={this.onSpouseCompanyNameChange}
                                                spouseMobile={this.state.spouseMobile}
                                                onSpouseMobileChange={this.onSpouseMobileChange}
                                                isValidEmergencyContact={this.isValidEmergencyContact()}
                                                onEmergencyContactContinuePressed={this.onEmergencyContactContinuePressed}
                                            />
                                        </Collapse>
                                    </ListGroupItem>
                                    <ListGroupItem className="justify-content-between">
                                        <div
                                            className="submit-more-info-form-label"
                                            data-index="4"
                                            onClick={this.isValidPreferredBank() ? this.toggleSubmitMoreInfo : null}
                                        >
                                            {this.toggleTabIcon(4)} Where to receive your money {this.toggleStatus(4)}
                                        </div>
                                        <Collapse className="ml-4 mb-responsive-collapse" isOpen={this.state.collapse4}>

                                            <DisbursementBank
                                                preferredBank={this.state.preferredBank}
                                                onPreferredBankChange={this.onPreferredBankChange}
                                                bankAccountNumber={this.state.bankAccountNumber}
                                                onBankAccountNumberChange={this.onBankAccountNumberChange}
                                                isNotJoinAccNo={this.state.isNotJoinAccNo}
                                                onDeclareJoinAccChange={this.onDeclareJoinAccChange}
                                                isAW={this.props.eligibility.isAW}
                                                isValidPreferredBank={this.isValidPreferredBank()}
                                                onPreferredBankContinuePressed={this.onPreferredBankContinuePressed}
                                            />
                                        </Collapse>
                                    </ListGroupItem>
                                    <ListGroupItem className="justify-content-between">
                                        <div
                                            className="submit-more-info-form-label"
                                            data-index="5"
                                            onClick={this.isValidFatcaCrs() ? this.toggleSubmitMoreInfo : null}
                                        >
                                            {this.toggleTabIcon(5)} Declaration {this.toggleStatus(5)}
                                        </div>
                                        <Collapse className="ml-4 mb-responsive-collapse" isOpen={this.state.collapse5}>
                                            <Fatca
                                                fatcaDeclaration={this.state.fatcaDeclaration}
                                                onFatcaDeclarationChange={this.onFatcaDeclarationChange}
                                                fatcaCountryBirth={this.state.fatcaCountryBirth}
                                                onFatcaCountryBirthChange={this.onFatcaCountryBirthChange}
                                                isValidFatcaCrs={this.isValidFatcaCrs()}
                                                onFatcaCrsContinuePressed={this.onFatcaCrsContinuePressed}
                                            />
                                            {/* <Crs
                                                taxResidence={this.state.taxResidence}
                                                onTaxResidenceChange={this.onTaxResidenceChange}
                                                isNonMalaysianTaxResident={this.isNonMalaysianTaxResident()}
                                                tinArray={this.state.tinArray}
                                                onAddMoreTinPressed={this.onAddMoreTinPressed}
                                                isValidFatcaCrs={this.isValidFatcaCrs()}
                                                onFatcaCrsContinuePressed={this.onFatcaCrsContinuePressed}
                                                onTaxJurisCountryChange={(itemValue, i) => { this.onTaxJurisCountryChange(itemValue, i); }}
                                                onTinNumberChange={(result, i) => this.onTinNumberChange(result, i)}
                                                onNoTinPressed={(i) => this.onNoTinPressed(i)}
                                                onRemoveTinPressed={(i) => this.onRemoveTinPressed(i)}
                                                onReasonOfNoTinChange={(itemValue, i) => this.onReasonOfNoTinChange(itemValue, i)}
                                                onNoTinOtherReasonChange={(result, i) => this.onNoTinOtherReasonChange(result, i)}
                                            /> */}
                                        </Collapse>
                                    </ListGroupItem>
                                </ListGroup>
                            </Col>
                        </Row>
                    </Container>
                    <NeedHelp
                        name={this.props.eligibility.name}
                        mobile={this.props.eligibility.mobile}
                        email={this.props.eligibility.email}
                        currentScreen={PloanScreensList.SCREEN_PROVIDE_MORE_INFO}
                    />
                </Section>
                {this.renderFooterProceedButton(this.state.index)}
                <Footer />
            </>
        );
    }
}

const mapStateToProps = state => ({
    moreAboutYourself: state.moreAboutYourself,
    employmentDetails: state.employmentDetails,
    emergencyContact: state.emergencyContact,
    preferredBank: state.preferredBank,
    fatcaCrs: state.fatcaCrs,
    eligibility: state.eligibility,
    calculator: state.calculator,
    webserviceConfig: state.webserviceConfig,
    initialLoanOffer: state.initialLoanOffer,
});

const mapDispatchToProps = dispatch => ({

    updateMoreAboutYourselfStore: (info) => {
        dispatch(moreAboutYourself.updateMoreAboutYourselfInfo(info))
    },
    clearMoreAboutYourselfStore: () => {
        dispatch(moreAboutYourself.clearMoreAboutYourselfInfo())
    },
    updateEmploymentDetailsStore: (info) => {
        dispatch(employmentDetails.updateEmploymentDetailsInfo(info))
    },
    clearEmploymentDetailsStore: () => {
        dispatch(employmentDetails.clearEmploymentDetailsInfo())
    },
    updateEmergencyContactStore: (info) => {
        dispatch(emergencyContact.updateEmergencyContactInfo(info))
    },
    clearEmergencyContactStore: () => {
        dispatch(emergencyContact.clearEmergencyContactInfo())
    },
    updatePreferredBankStore: (info) => {
        dispatch(preferredBank.updatePreferredBankInfo(info))
    },
    clearPreferredBankStore: () => {
        dispatch(preferredBank.clearPreferredBankInfo())
    },
    updateFatcaCrsStore: (info) => {
        dispatch(fatcaCrs.updateFatcaCrsInfo(info))
    },
    clearFatcaCrsStore: () => {
        dispatch(fatcaCrs.clearFatcaCrsInfo())
    },
    updateEligibilityStore: (info) => {
        dispatch(eligibility.updateEligibilityInfo(info))
    },
    updateInitialLoanOfferStore: (info) => {
        dispatch(initialLoanOffer.updateInitialLoanOfferInfo(info))
    }
});

export default withRefreshHandler(withCustomAlert(connect(mapStateToProps, mapDispatchToProps)(SubmitMoreInfo)));
