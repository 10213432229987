import React from 'react';
import * as data from "../../data/globalData";
import PropTypes from 'prop-types';
import { FormHelper } from '../../services';

class Declaration extends React.PureComponent {

    render() {

        const { isEnableHyperlink, isIslamic } = this.props;

        return (
            <div>
                “Bank” herein refers to Alliance Bank Malaysia Berhad and/or Alliance Islamic Bank Berhad
                <br />
                <br />
                I have read and understood the {" "}
                {isEnableHyperlink ? (
                    <a href={data.docPath.ncp_eng} onClick={(e) => FormHelper.downloadPDFInIE(e, data.docPath.ncp_eng)} download>Notice & Choice Principle Statement – Personal Data Protection Act 2010</a>
                ) : (
                        'Notice & Choice Principle Statement – Personal Data Protection Act 2010'
                    )
                }
                {" "} of the Bank.
                <br />
                <br />
                I have hereby received, read, understood and agree to the {" "}
                {isEnableHyperlink ? (
                    <a href={isIslamic ? data.docPath.tnc_islamic : data.docPath.tnc} onClick={(e) => FormHelper.downloadPDFInIE(e, isIslamic ? data.docPath.tnc_islamic : data.docPath.tnc)} download>Terms & Conditions</a>
                ) : (
                        'Terms & Conditions'
                    )
                }
                {" "} , {" "}
                {isEnableHyperlink ? (
                    <a href={isIslamic ? data.docPath.pds_islamic : data.docPath.pds} onClick={(e) => FormHelper.downloadPDFInIE(e, isIslamic ? data.docPath.pds_islamic : data.docPath.pds)} download>Product Disclosure Sheet</a>
                ) : (
                        'Product Disclosure Sheet'
                    )}
                {" "} and {" "}
                {isEnableHyperlink ? (
                    <a href="https://www.alliancebank.com.my/privacy-policy.aspx" target="_blank">Privacy Policy</a>
                ) : (
                        'Privacy Policy'
                    )}
                {" "}
                provided in the hyper-links herein before I proceed to submit the
                Alliance Personal Financing Application. By selecting to click on
                the "Submit", I agree that I am deemed to have accepted and agreed
                to be bound by the Alliance Personal Financing Terms and Conditions,
                the Product Disclosure Sheet, Privacy Policy and hereby confirm that the
                Declaration/Disclosure made herein are true, accurate and complete.
                <br />
                <br />
                I irrevocably consent to and authorise the Bank to disclose to any
                financial institutions granting or intending to grant any credit
                facilities to me, any credit bureaus, any credit reference agencies,
                Bank Negara Malaysia, any authority/body having jurisdiction over the
                Bank, any security parties (including guarantors), the Bank’s auditors,
                lawyers and/or authorised agents or to such person(s) and or entity(ies)
                as permitted by law, any information relating to my/our affairs, banking
                accounts or conducts thereof (including my credit standing) as the Bank
                deems necessary or expedient.
                <br />
                <br />
                I hereby declare and confirm that the information given herein is accurate 
                and complete and authorise the Bank to verify this from any source the Bank 
                may choose and further to seek and obtain credit information related to my 
                application from any credit reporting agencies governed by Credit Reporting 
                Agencies Act 2010. I understand that this information will be used for the 
                purpose of assessing my creditworthiness for this application, conducting 
                credit review for the Bank’s credit risk management purposes and for assessing 
                future financing opportunities. I hereby confirm that
                save as otherwise disclosed in the application form, none of my spouse(s),
                parents, children, brothers, sisters, their spouses and/or their financial
                dependants and/or my agents and guarantors are in the employment of the
                Bank or its subsidiaries or are related to a director, officer or employee
                of the Bank or its subsidiaries. I undertake to inform the Bank immediately
                if any such relationship is established/intended to be established. I
                acknowledge that approval of the application is at the sole discretion of
                the Bank. The Bank reserves the right not to disclose or provide any reason
                if any of my application is unsuccessful. I further understood that it is a
                requirement by Bank Negara Malaysia (BNM) that information relating to this
                application, whether successful or otherwise, must be updated to the Central
                Credit Reference Information System (CCRlS) database maintained by BNM. I
                understand that the Bank reserves the absolute right to decline my application
                as the Bank deems fit. I hereby irrevocably authorise the Bank the right to
                check my credit standing upon my application for loan/financing hereunder
                without further consent  from me. In the event that the account number provided
                above is for a joint account and/or is inaccurate, I hereby declare and agree
                that I shall continue to be solely liable to pay the Personal loan/Financing
                Monthly Instalments and any unpaid payments due together with any interest
                accrued, any other compensation charges and any other fees and charges imposed
                by the Bank as stipulated in the Terms and Conditions, irrespective of whether
                or not the Personal Loan/Financing Facility (“the Facility”) was withdrawn
                and/or utilised by the joint account holder. The Bank is NOT liable if the
                Facility is deposited into and/or paid to a wrong account due to and/or as a
                result of any negligence, omission, fraud, inaccurate and/or incorrect
                information and/or misrepresentation provided by me in this application form.
                <br />
                <br />
                Credit Card and/or Personal Loan Balance Refinancing <br />
                <br />
                Credit Card and/or Personal Loan/Financing Balance Refinancing is only
                available for Credit Card and/or Personal Loan/Financing Account(s)
                with other banks and other financial institution in Malaysia that accepts
                IBG payment and a maximum of five (5) Balance Refinancing accounts per
                customer. Minimum Balance Refinancing amount per account is RM100 and
                shall be rounded to the nearest RM100. If any information given herein
                becomes inaccurate or misleading or changes in any way and at any time,
                whether before this application is approved or prior to the disbursement
                of the Personal Loan/Financing applied herein, I shall promptly notify
                the Bank of such changes. The Bank may at its sole discretion approve or
                reject my application without assigning any reason. For the purpose
                herein I hereby irrevocably authorise the Bank to check and receive all
                information relating to my credit card and/or personal loan/financing
                account with other banks or financial institutions. The proposed transfer
                or refinancing is based solely on my instructions and has no implication
                on my relationship with any credit card and/or personal loan issuer. The
                Bank is not obliged to assign any reason to me in respect of the exercise
                of its discretion pursuant to this clause. In the situation where I have
                opted for the Credit Card and/or Personal Loan/Financing Balance
                Refinancing at the point of application for the Facility to utilise the
                Facility for the payment of my existing credit card and/or personal
                loan/financing facility, I shall pay the shortfall between the Balance
                Refinancing amount and outstanding balance of my existing credit card
                and/or personal loan/financing facility. The Bank will not be responsible
                in any manner whatsoever for any losses or damages incurred, if any,
                arising from the shortfall of the Facility to repay my existing credit
                card and/or personal loan/financing facility. I acknowledge that acceptance
                of this application is complete upon Bank crediting the approved
                Loan/Financing Amount into my indicated account. In the event that the
                Facility amount applied for is the same as the approved amount by the Bank,
                the Bank has the absolute right to transfer, disburse/pay the amount of the
                Credit Card and/or Personal Financing Balance Refinancing applied for without
                prior notification to me or my consent.
                <br />
                <br />
                <b><u>Agency Appointment (applicable to Islamic Personal Financing-<i>i</i> only)</u></b>
                <br />
                <br />
                <ol>
                    <li>
                        In order to facilitate the disbursement of the Facility, I irrevocably and
                        unconditionally appoint the Bank to be my agent to (a) purchase the Commodity
                        from the Bank by accepting and executing the sale transaction on my behalf (b)
                        sell the Commodity to any third party Commodity Purchaser on my behalf.
                    </li>
                    <li>
                        I agree that the Bank as my agent shall at all times (a) be authorised to sign
                        and execute all documents and do all acts and observe and perform all obligations
                        required to be done in connection with its appointment as my agent, or imposed
                        under any sale transaction to the third party purchaser of the Commodity; and (b)
                        be required to do all the administrative duties regarding the holding and the sale
                        of the Commodity (c) allow me to obtain possession of the Commodity if requested
                        by me (provided that the Bank has yet to sell the Commodity to any third party) (d)
                        receive Sale Proceeds on my behalf from third party purchasers of the Commodity, to
                        hold the Sale Proceeds on trust for me and to remit the Sale Proceeds or any part
                        thereof to me into my account with the Bank or in the manner mutually agreed by the
                        parties.
                    </li>
                    <li>
                        The Bank’s appointment shall be subject to me purchasing the Commodity from the Bank,
                        failing which, the Bank is deemed not to have acted as my agent for that particular
                        sale transaction.
                    </li>
                    <li>
                        Notwithstanding any sale for the Commodity by me or by the Bank as my agent and/or
                        any non-delivery and/or dispute whatsoever in relation to the Commodity that I may
                        have with the third party purchaser to whom the Bank sold the Commodity as agent on
                        my behalf subsequent to me entering into sale transaction with the Bank but prior to
                        the full settlement of the Selling Price of the Commodity and the indebtedness created
                        under the said sale transaction, it shall not in any way impair or absolve my
                        obligations as set out in the Application Form.
                    </li>
                    <li>
                        I do not deem the Bank’s appointment as a partnership or a joint venture, nor does it
                        establish a relationship of principal and agent in any other relationship between us
                        and the appointment shall cease upon expiry of the Facility.
                    </li>
                    <li>
                        I undertake to indemnify the Bank from any losses, costs, expenses or damages that the
                        Bank may suffer or incur as a result of fulfilling the Bank’s agency function as set out
                        above.
                    </li>
                    <li>
                        This agency appointment shall continue to be valid so long as the Bank continues to grant
                        the Facility and shall cease upon request by me for cancellation or termination of the
                        Facility by the Bank.
                    </li>
                </ol>
            </div>
        );
    }
}


Declaration.defaultProps = {
    isEnableHyperlink: true,
    isIslamic: false
}

Declaration.propTypes = {
    isEnableHyperlink: PropTypes.bool,
    isIslamic: PropTypes.bool
}

export default Declaration;



