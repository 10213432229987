import getStateByPostcode from '../getStateByPostcode';

export const isNotEmptyObject = (input) => {
  return Object.keys(input).length > 0
}

export const isOnlyNumber = (input) => {
  return /^[0-9]*$/ig.test(input)
}

export const isOnlyAlphanumeric = (input) =>{
  return /^[a-z0-9]*$/ig.test(input)
}

export const isEmailFormat = (input) => {
  let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(input);
}

export const isValidName = (input) => {
  let regex = /^(?!.*[ ]{2})[a-zA-Z@'/-\s]*$/
  return regex.test(input);
}

export const isValidAddress = (input) => {
  // allowed symbols #-:',./
  // not allowed symbols ~;`!@$%^&*()_+{}[]|\<>?=
  // some symbols allowed in English keyboard but not allowed in Chinese Keyboard (Eg. :'",.)
  let regex = /^[a-zA-Z 0-9\#\-\:\'\,\.\/\s]*$/
  return regex.test(input);
}

export const isOnlyLetters = (input) => {
  return /^[A-Za-z]*$/ig.test(input)
}

export const isEligibleICNumber = (input) => {
  var regex = /^\d{6}-\d{2}-\d{4}$/;
  var getYear = input.substring(0, 2);
  var yearIn4Digit = (getYear < 90) ? '20' + getYear : '19' + getYear;
  var currentYear = new Date().getFullYear();

  if (yearIn4Digit > currentYear) {
    yearIn4Digit = '19' + getYear;
  }
  return regex.test(input) && ((currentYear - yearIn4Digit) >= 21 && (currentYear - yearIn4Digit) <= 65);
}

export const isICFormat = (input) => {
  var regex = /^\d{6}-\d{2}-\d{4}$/;
  return regex.test(input);
}

export const isValidICBasedOnFirst6Digit = (icNo) =>{
  let [ icYearDigit, icMonthDigit, icDateDigit] = [icNo.substring(0,2),icNo.substring(2,4),icNo.substring(4,6)]
  if(isValidDay(icYearDigit, icMonthDigit, icDateDigit))
  {
    return true;
  }else{
    return false;
  }
}

export const isLocalPostcode = (input) => {
  let regex = /^[0-9]*$/ig;
  let invalidInputLength = false;
  let stateCode = getStateByPostcode(input);
  let invalidStateCode = false;

  if (input.length >= 1 && input.length < 5) { invalidInputLength = true; }
  if (input && (stateCode == null)) { invalidStateCode = true; }
  return regex.test(input) && !invalidInputLength && !invalidStateCode;
}

export const isForeignPostcode = (input) => {
  let invalidInputLength = false;
  if (input.length >= 1 && input.length < 5) { invalidInputLength = true; }
  if (input.length === 0) { invalidInputLength = false; }
  return !invalidInputLength;
}

export const isOnlyAlphabetAndSpace = (input) => {
  let regex = /^[a-z\s]*$/ig
  return regex.test(input);
}

export const isValidCurrency = (input) => {
  let regex = /^[0-9,.]*$/ig
  return regex.test(input);
}

export const isValidLocalMobileNumber = (input) => {
  // input format = +6017-2358777
  const removedPrefixVal = input.replace('+60', '');
  let regex = /^\d{2}-[0-9]{7,8}$/;
  let addedBackPrefixVal;
  if (regex.test(removedPrefixVal)) {
    addedBackPrefixVal = "+60" + removedPrefixVal
  }
  // addedBackPrefixVal format = +6017-2358777, regex is to make sure all is numeric
  return addedBackPrefixVal;
}

export const isValidCompanyTel = (input) => {
  const removedPlusSixVal = input.replace('+6', '');
  const firstDigit = removedPlusSixVal.substr(0, 1); 
  let regex = /^([0-9 ]+)$/
  // malaysia number 
  if (firstDigit === "0" && removedPlusSixVal.length > 10 && regex.test(removedPlusSixVal)) {
    return true
  }
  // singapore number
  // if (firstDigit === "5" && removedPlusSixVal.length > 10 && regex.test(removedPlusSixVal)) {
  //   return true
  // }
  else {
    return false
  }
}

export const isValidDay = (year, month, day) => {
  
  if (!year || !month || !day)
    return false;

  let yearString = year.toString();
  let monthString = month.toString();
  let dateString = day.toString();
  
  if (yearString.length < 2 || yearString.length > 4)
    return false;
   
  if (monthString.length < 1 || monthString.length > 2 || monthString < 1 || monthString > 12)
    return false;
   
  if (dateString.length < 1 || dateString.length > 2 || dateString < 1 || dateString > 31)
    return false;

  if (yearString.length == 2) {
    yearString = (yearString < 90) ? '20' + year : '19' + year;
    let currentYear = new Date().getFullYear();
    if (yearString > currentYear) {
      yearString = '19' + year;
    }
  }

  if (monthString.length === 1)
    monthString = '0' + monthString;
  

  if (dateString.length === 1)
    dateString = '0'+ dateString;
  
  let completeDateString = `${yearString}-${monthString}-${dateString}`;
  let date = new Date(completeDateString);

  if(isNaN(date.getDate()))
  return false;

  if(date.getDate()!== Number(dateString))
  return false;

  return true;
}

export const isNotBeginOrEndWithSpace = (input) => {
  let regex = /^\S/;
  let regex2 = /\S$/;
  return regex.test(input) && regex2.test(input);
}

export const isValidEcid = (ecid) => {
  if(!ecid)
    return false;
  if(ecid.length <= 0 || ecid.length > 100)
    return false;
  return true;
}

export const isValidDxidOrXuid = (dxidOrXuid) => {
  if(!dxidOrXuid)
    return false;
  if(dxidOrXuid.length <= 0 || dxidOrXuid.length > 100)
    return false;
  return true;
}

export const diff_months = (dt2, dt1) => {
  //This will cover the following;
  // 1st Jan to 31st Jan ---> 30days ---> will result in 0 (logical since it is not a full month)
  // 1st Feb to 1st Mar ---> 28 or 29 days ---> will result in 1 (logical since it is a full month)
  // 15th Feb to 15th Mar ---> 28 or 29 days ---> will result in 1 (logical since a month passed)
  // 31st Jan to 1st Feb ---> 1 day ---> will result in 0 (obvious but the mentioned answer in the post results in 1 month)

  var months = dt2.getMonth() - dt1.getMonth() + (12 * (dt2.getFullYear() - dt1.getFullYear()));
  if(dt2.getDate() < dt1.getDate()){
    months--;
  }
  return months;
}

export const isValidCmsid = (cmsid) => {
  if(!cmsid)
    return false;
  if(cmsid.length <= 0 || cmsid.length > 100)
    return false;
  return true;
}