import React, { Component } from "react";
import SectionTitle from "../../components/SectionTitle/index";
import Section from "../../components/Section";
import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";
import ReCAPTCHA from "react-google-recaptcha";
import Calculator from "../../components/Calculator"
import { FormHelper, Webservice, PayloadGenerator, JWT } from '../../services';
import {
  BusinessList, BusinessSectorList, OccupationList, OccupationSectorList,
  BumiList, CountriesList, StateList, EmploymentTypeList, NationalityList, GenderList, MaritalStatusList, EducationLevelList,
  LoanTypeList, InsurancePremium, RaceList, ErrorList, PloanScreensList, PreferredMailingAddressList
} from '../../constant';
import { getStateByPostcode, Replace } from '../../services/formHelper';
import { eligibility, webserviceConfig, calculator } from '../../reduxStore/actions';
import {
  Container, Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Fade
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faPen } from "@fortawesome/free-solid-svg-icons";
import Declaration from "../../components/Declaration";
import Pdpa from "./pdpa";
import { connect } from 'react-redux';
import TncDeclaration from "./tncDeclaration";
import SlidingErrorAlert from '../../components/SlidingErrorAlert';
import MobileNumberTac from '../../components/tac';
import Routes from '../../routes';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { withCustomAlert, withRefreshHandler } from '../../hoc';
import { isValidSession, canApplyLoanApplication } from '../../services/session';
import BaseComponent from '../../class/baseComponent';
import Environment from '../../environment';
import NeedHelp from "../../components/NeedHelp";
import styles from "./eligibility.scss";
import * as data from "../../data/globalData";
import inputStyles from "../../components/Input/Input.module.scss";
import Nonbankdebt from "./nonbankdebt";

class VerifyIdentity extends BaseComponent {

  otpUuid = '';

  constructor(props) {
    super(props);

    const {
      isFromAOP, name, gender, email, nationality, bumi, race, address, address2, country, state, foreignState,
      city, postcode, company, occupationSector, occupation,
      businessSector, natureBusiness, monthlyNetIncome, mobile, isAcceptTnc, isAcceptedPDPDConsent, hasNonbankdebt, nonBankDebtAmt,
      insurancePremium, maritalStatus, educationLevel, preferredMailingAddress,
      companyAddress, companyAddress2, companyPostcode, companyCity, companyLocalState, companyForeignState, companyCountry
    } = this.props.eligibility;

    const {
      loanAmount, selectedTenure, selectedLoanType, monthlyInstallment, maxLoanAmount, minLoanAmount,
      tenureOptions, interestRate, minAgeEligibility, loanTypes, grossMonthlyIncome, tenureInterestMapping,
      minMonthlyIncome, maxLoanAmountMapping, icNo, selectedEmploymentType, maxAgeEligibility, 
      isCashBackCampaign, installmentBeforeCashBack, installmentAfterCashBack, cashBackTenureInterestMapping,
      cashBack, totalCashBack
    } = this.props.calculator;

    this.state = {

      verificationCode: '',

      shouldShowLoadingIndicator: false,
      showTnCModal: false,
      showCalculatorModal: false,
      hasVerifyReCaptcha: true,
      requestedOTP: false,
      hasVerifiedOTP: false,
      isCallingWebservice: false,

      isFromAOP, name, gender, email, nationality, bumi, race, address, address2, country, state, foreignState,
      city, postcode, company, selectedEmploymentType, occupationSector, occupation,
      businessSector, natureBusiness, monthlyNetIncome, mobile, isAcceptTnc, isAcceptedPDPDConsent, hasNonbankdebt, nonBankDebtAmt,
      insurancePremium, maritalStatus, educationLevel, preferredMailingAddress,
      companyAddress, companyAddress2, companyPostcode, companyCity, companyLocalState, companyForeignState, companyCountry,

      loanAmount, selectedTenure, selectedLoanType, monthlyInstallment, maxLoanAmount, minLoanAmount,
      tenureOptions, interestRate, minAgeEligibility, loanTypes, tenureInterestMapping,
      minMonthlyIncome, maxLoanAmountMapping, maxAgeEligibility,

      monthlyGrossIncome: grossMonthlyIncome,
      nric: icNo,
      isCashBackCampaign, installmentBeforeCashBack, installmentAfterCashBack, cashBackTenureInterestMapping
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.autoPopulateGenderByIC();
    console.log('this.props.calculator', this.props.calculator)
  }

  isComeFromLanding = () => {
    return this.props.location.fromLanding;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      nric, country, postcode, occupationSector, businessSector, verificationCode, state, hasVerifyReCaptcha,
      companyCountry, companyPostcode, companyLocalState
    } = this.state;

    const {
      isICFormat, isLocalPostcode
    } = FormHelper.Validation;

    const prevNric = prevState.nric;
    const prevCountry = prevState.country;
    const prevLocalPostCode = prevState.postcode;
    const prevOccupationSector = prevState.occupationSector;
    const prevBusinessSector = prevState.businessSector;
    const prevVerificationCode = prevState.verificationCode;
    const prevSelectedState = prevState.state;
    const prevCompanyCountry = prevState.companyCountry;
    const prevCompanyLocalPostCode = prevState.companyPostcode;
    const prevCompanyLocalSelectedState = prevState.companyLocalState

    //clear state, postcode, city, foreignState if country changed
    if (JSON.stringify(country) !== JSON.stringify(prevCountry)) {
      this.setState({ state: '', postcode: '', city: '', foreignState: '' })
    }

    //auto populate state if country is Malaysia and is a valid local state code
    if (postcode !== prevLocalPostCode && country && country.value === 'MY'
      && postcode.length === 5 && isLocalPostcode(postcode)) {
      const stateCode = getStateByPostcode(postcode);
      const stateObj = StateList.find(stateObject => stateObject.value === stateCode)
      this.setState({ state: stateObj })
    }

    //clear company state, postcode, city, foreignState if country changed
    if (JSON.stringify(companyCountry) !== JSON.stringify(prevCompanyCountry)) {
      this.setState({
        companyLocalState: '',
        companyPostcode: '',
        companyCity: '',
        companyForeignState: ''
      })
    }

    //auto populate company state if company country is Malaysia and is a valid local state code
    if (companyPostcode !== prevCompanyLocalPostCode && companyCountry && companyCountry.value === 'MY'
      && companyPostcode.length === 5 && FormHelper.Validation.isLocalPostcode(companyPostcode)) {
      const companyStateCode = getStateByPostcode(companyPostcode);
      const companyStateObj = StateList.find(companyStateObject => companyStateObject.value === companyStateCode)
      this.setState({ companyLocalState: companyStateObj })
    }

    //clear company postcode if company state changed and company country is malaysia
    if (companyCountry && companyCountry.value === 'MY' &&
      Object.keys(prevCompanyLocalSelectedState).length > 0 &&
      (JSON.stringify(companyLocalState) !== JSON.stringify(prevCompanyLocalSelectedState)) &&
      getStateByPostcode(companyPostcode) !== companyLocalState.value) { //only clear postcode if the postcode does not map with state
      console.log('companyLocalState:', companyLocalState)
      this.setState({ companyPostcode: '' })
    }

    //clear occupation if occupation sector changed
    if (occupationSector && occupationSector !== prevOccupationSector) {
      this.setState({ occupation: '' })
    }

    //clear natureBusiness if businessSector changed
    if (businessSector && businessSector !== prevBusinessSector) {
      this.setState({ natureBusiness: '' })
    }

    //auto populate gender based on myKad last digit
    if (prevNric !== nric && isICFormat(nric)) {
      this.autoPopulateGenderByIC();
    }

    //verify tac when 6 number is entered
    if (verificationCode !== prevVerificationCode && verificationCode.length >= 6) {
      this.validateTac();
    }

    //clear postcode if state changed and country is malaysia
    if (country && country.value === 'MY' &&
      Object.keys(prevSelectedState).length > 0 && //has previous state
      (JSON.stringify(state) !== JSON.stringify(prevSelectedState)) &&
      getStateByPostcode(postcode) !== state.value) { //only clear postcode if the postcode does not map with state
      console.log('state:', state)
      this.setState({ postcode: '' })
    }
  }

  autoPopulateGenderByIC = () => {
    const { nric } = this.state;
    const icWithoutDash = nric.replace(/-/g, '');
    if (icWithoutDash) {
      const lastDigit = parseInt(icWithoutDash.charAt(icWithoutDash.length - 1));
      if (lastDigit % 2 == 1) {
        this.setState({ gender: { value: 'M', label: 'Male' } })
      }
      else if (lastDigit % 2 != 1) {
        this.setState({ gender: { value: 'F', label: 'Female' } })
      }
    }
  }

  onNameChange = (name) => {
    this.setState({ name })
  }

  onNameBlur = () => {
    this.setState(prevState => ({
      name: prevState.name && prevState.name.trim()
    }))
  }

  onICNumberChange = (nric) => {
    this.setState({ nric })
  }

  onGenderChange = (gender) => {
    this.setState({ gender })
  }

  onEmailChange = (email) => {
    this.setState({ email })
  }

  onMaritalStatusChange = (maritalStatus) => {
    this.setState({ maritalStatus })
  };

  onRaceChange = (race) => {
    this.setState({
      race,
      bumi: FormHelper.Getter.getBumiStatusBasedOnRace(race.value)
    })
  }

  onEducationLevelChange = (educationLevel) => {
    this.setState({ educationLevel })
  };

  onNationalityChange = (nationality) => {
    this.setState({ nationality })
  };

  onAddressChange = (address) => {
    this.setState({ address })
  };

  onAddress2Change = (address2) => {
    this.setState({ address2 })
  };

  onPostCodeChange = (postcode) => {
    this.setState({ postcode })
  };

  onCityChange = (city) => {
    this.setState({ city })
  };

  onLocalStateChange = (state) => {
    this.setState({ state })
  };

  onForeignStateChange = (foreignState) => {
    this.setState({ foreignState })
  };

  onCountryChange = (country) => {
    this.setState({ country })
  };

  onCompanyChange = (company) => {
    this.setState({ company })
  };

  onEmploymentTypeChange = (selectedEmploymentType) => {
    this.setState({ selectedEmploymentType })
  };

  onPreferredMailingAddressChange = (preferredMailingAddress) => {
    this.setState({ preferredMailingAddress })
  }

  onCompanyAddressChange = (companyAddress) => {
    this.setState({ companyAddress })
  }

  onCompanyAddress2Change = (companyAddress2) => {
    this.setState({ companyAddress2 })
  }

  onCompanyPostcodeChange = (companyPostcode) => {
    this.setState({ companyPostcode })
  }

  onCompanyCityChange = (companyCity) => {
    this.setState({ companyCity })
  }

  onCompanyLocalStateChange = (companyLocalState) => {
    this.setState({ companyLocalState })
  }

  onCompanyForeignStateChange = (companyForeignState) => {
    this.setState({ companyForeignState })
  }

  onCompanyCountryChange = (companyCountry) => {
    this.setState({ companyCountry })
  }

  onOccupationSectorChange = (occupationSector) => {
    this.setState({ occupationSector })
  };

  onOccupationChange = (occupation) => {
    this.setState({ occupation })
  };

  onBusinessSectorChange = (businessSector) => {
    this.setState({ businessSector })
  };

  onNatureBusinessChange = (natureBusiness) => {
    this.setState({ natureBusiness })
  };

  onMonthlyGrossIncomeChange = (monthlyGrossIncome) => {
    if (!FormHelper.Validation.isOnlyNumber(monthlyGrossIncome)) {
      monthlyGrossIncome = FormHelper.Replace.replaceAllExceptNumber(monthlyGrossIncome);
    }
    monthlyGrossIncome = monthlyGrossIncome ? parseInt(monthlyGrossIncome) : "";
    this.setState({ monthlyGrossIncome })
  };

  onInsurancePremiumChange = (insurancePremium) => {
    this.setState({ insurancePremium })
  };

  onMonthlyNetIncomeChange = (monthlyNetIncome) => {
    this.setState({ monthlyNetIncome })
  };

  onMobileNumberChange = (mobile) => {
    mobile = FormHelper.phoneSeparator(mobile);
    this.setState({ mobile })
  };

  captcaOnChange = () => {
    this.setState({ hasVerifyReCaptcha: true });
  };

  onPdpaChange = (isAcceptedPDPDConsent) => {
    this.setState({ isAcceptedPDPDConsent })
  }

  onNonbankdebtChange = (hasNonbankdebt) => {
    this.setState({ hasNonbankdebt })
  }

  onNonBankDebtAmtChange = (nonBankDebtAmt) => {
    if (!FormHelper.Validation.isOnlyNumber(nonBankDebtAmt)) {
        nonBankDebtAmt = FormHelper.Replace.replaceAllExceptNumber(nonBankDebtAmt);
    }
    nonBankDebtAmt = nonBankDebtAmt ? parseInt(nonBankDebtAmt) : "";
    this.setState({ nonBankDebtAmt })
  };

  onTncChange = () => {
    this.setState(prevState => ({ isAcceptTnc: !prevState.isAcceptTnc }))
  }

  onTnCAgree = () => {
    this.setState({
      isAcceptTnc: true,
      showTnCModal: false
    })
  }

  toggleTnCModal = () => {
    this.setState(prevState => ({
      showTnCModal: !prevState.showTnCModal
    }))
  }

  toogleCalculatorModal = () => {
    this.setState(prevState => ({
      showCalculatorModal: !prevState.showCalculatorModal
    }))
  }

  isRequiredMonthlyNetIncome = () => {
    const { selectedEmploymentType } = this.state;
    if (selectedEmploymentType && (selectedEmploymentType.value === 'SESB' || selectedEmploymentType.value === 'SESP' || selectedEmploymentType.value === 'CE'))
      return true;
    return false;
  }

  sortBusinessSectorList = () => {
    let newSortedBusinessSectorDropdown = BusinessSectorList.sort((item1, item2) => item1.label.localeCompare(item2.label))
    return newSortedBusinessSectorDropdown;
  }

  sortOccupationSectorList = () => {
    let newSortedOccupationSectorDropdown = OccupationSectorList.sort((item1, item2) => item1.label.localeCompare(item2.label))
    return newSortedOccupationSectorDropdown;
  }

  filterOccupationDropdownBasedOnSector = () => {
    const { occupationSector } = this.state;

    if (!occupationSector)
      return OccupationList;

    let newOccupationDropdown = OccupationList.filter(item => item.sector === occupationSector.value)
    let newSortedOccupationDropdown = newOccupationDropdown.sort((item1, item2) => item1.label.localeCompare(item2.label))
    return newSortedOccupationDropdown;
  }

  filterBusinessDropdownBasedOnSector = () => {
    const { businessSector } = this.state;

    if (!businessSector)
      return BusinessList;

    let newBusinessDropdown = BusinessList.filter(item => item.sector === businessSector.value)
    let newSortedBusinessDropdown = newBusinessDropdown.sort((item1, item2) => item1.label.localeCompare(item2.label))
    return newSortedBusinessDropdown;
  }

  onMonthlyInstallmentChange = (monthlyInstallment) => {
    this.setState({ monthlyInstallment })
  }

  onInstallmentBeforeCashback = (installmentBeforeCashBack) => {
    console.log('installmentBefore', installmentBeforeCashBack)
    this.setState({ installmentBeforeCashBack, monthlyInstallment: installmentBeforeCashBack })
  }

  onInstallmentAfterCashback = (installmentAfterCashBack) => {
    console.log('installmentAfter', installmentAfterCashBack)
    this.setState({ installmentAfterCashBack})
  }

  onLoanAmountChange = (loanAmount) => {
    this.setState({ loanAmount })
  }

  onTenureChange = (selectedTenure) => {
    let { isCashBackCampaign } = this.state;
    this.setState({
      selectedTenure,
      interestRate: !!isCashBackCampaign? this.state.cashBackTenureInterestMapping[selectedTenure.value] : this.state.tenureInterestMapping[selectedTenure.value]
    })
  }

  onCashBack = (cashBack) => {
    console.log('cashback', cashBack)
    this.setState({ cashBack })
  }

  onTotalCashBack = (totalCashBack) => {
    console.log('totalCashBack',totalCashBack)
    this.setState({ totalCashBack })
  }

  onLoanTypeChange = (selectedLoanType) => {
    this.setState({ selectedLoanType })
  }

  onVerificationCodeChange = (value) => {
    this.setState({
      verificationCode: value
    })
  }

  isValidICNo = (nric) => {
    if (FormHelper.Validation.isICFormat(nric) && FormHelper.Validation.isValidICBasedOnFirst6Digit(nric)) {
      return true;
    } else {
      return false;
    }
  }

  getPostCodeValidation = () => {
    const { country } = this.state;

    if (country.value === 'MY')
      return FormHelper.Validation.isLocalPostcode;
    else
      return FormHelper.Validation.isForeignPostcode;
  }

  isGrossIncomeMoreThanMinimumIncome = () => {
    const { monthlyGrossIncome, minMonthlyIncome } = this.state;
    if (monthlyGrossIncome >= minMonthlyIncome)
      return true;
    return false;
  }

  isLoanWithinMinAndMax = () => {
    const { maxLoanAmount, loanAmount, minLoanAmount } = this.state;
    if (loanAmount >= minLoanAmount && loanAmount <= maxLoanAmount)
      return true;
    return false;
  }

  isAgeEligibleToApplyLoan = () => {
    const { nric, selectedTenure, maxAgeEligibility, minAgeEligibility } = this.state;
    const customerAge = FormHelper.Getter.getAgeFromICNo(nric);
    const customerAgeUponLoanMaturity = customerAge + Number(selectedTenure.value);

    if (customerAge < minAgeEligibility || customerAge > maxAgeEligibility) {
      return `Your age must be between ${minAgeEligibility} and ${maxAgeEligibility}`;
    }

    if (customerAgeUponLoanMaturity > (maxAgeEligibility - 1)) {
      return `Your age will more than ${maxAgeEligibility} upon financing maturity date, please reduce your tenure years.`;
    }

    return "OK";
  }

  isGrossIncomeMoreMonthlyInstallment = () => {
    const { monthlyGrossIncome, monthlyInstallment } = this.state;
    if (monthlyGrossIncome > monthlyInstallment)
      return true;
    return false;
  }

  hasFilledCompanyAddress = () => {
    const {
      companyAddress, companyPostcode, companyCity, companyLocalState, companyCountry
    } = this.state;

    let hasFilledAll = companyAddress && companyCity && companyCountry

    if (!this.isCompanyPostCodeOptional())
      hasFilledAll = hasFilledAll && companyPostcode;

    if (!this.isCompanyStateOptional())
      hasFilledAll = hasFilledAll && companyLocalState;

    return hasFilledAll;
  }

  hasFilledInAllRequiredField = () => {
    const { name, nric, gender, email, nationality, bumi, race, address, country, city, company, selectedEmploymentType,
      occupationSector, occupation, businessSector, natureBusiness, monthlyGrossIncome, mobile, insurancePremium,
      maritalStatus, monthlyNetIncome, postcode, educationLevel, state, isAcceptedPDPDConsent, isAcceptTnc, preferredMailingAddress
    } = this.state;

    let hasFilledInAllRequiredField = name && nric && gender && nationality && bumi && race && address && country && city && company
      && selectedEmploymentType && occupationSector && occupation && businessSector && natureBusiness && monthlyGrossIncome
      && mobile && maritalStatus && educationLevel && email && preferredMailingAddress && this.hasFilledCompanyAddress() &&
      (isAcceptedPDPDConsent !== undefined && isAcceptedPDPDConsent !== null) && isAcceptTnc;

    if (!this.isPostCodeOptional())
      hasFilledInAllRequiredField = hasFilledInAllRequiredField && postcode;

    if (!this.isStateOptional())
      hasFilledInAllRequiredField = hasFilledInAllRequiredField && state;

    if (this.isRequiredMonthlyNetIncome())
      hasFilledInAllRequiredField = hasFilledInAllRequiredField && monthlyNetIncome;

    return hasFilledInAllRequiredField;
  }

  isValidPostCodeAccordingToCountry = () => {
    const { country, postcode } = this.state;

    if (country.value === 'MY' && !FormHelper.Validation.isLocalPostcode(postcode))
      return false;
    return true;
  }

  isPostCodeOptional = () => {
    const { country } = this.state;
    if (country.value === 'MY')
      return false;
    return true;
  }

  isStateOptional = () => {
    const { country } = this.state;
    if (country.value === 'MY')
      return false;
    return true;
  }

  getCompanyPostCodeValidation = () => {
    const { companyCountry } = this.state;

    if (companyCountry && companyCountry.value === 'MY')
      return FormHelper.Validation.isLocalPostcode;
    else
      return FormHelper.Validation.isForeignPostcode;
  }

  isValidCompanyPostCodeAccordingToCountry = () => {
    const { companyCountry, companyPostcode } = this.state;

    if (companyCountry && companyCountry.value === 'MY' && !FormHelper.Validation.isLocalPostcode(companyPostcode))
      return false;
    return true;
  }

  isCompanyPostCodeOptional = () => {
    const { companyCountry } = this.state;
    if (companyCountry && companyCountry.value === 'MY')
      return false;
    return true;
  }

  isCompanyStateOptional = () => {
    const { companyCountry } = this.state;
    if (companyCountry && companyCountry.value === 'MY')
      return false;
    return true;
  }

  isIslamic = () => {
    return this.props.calculator.selectedLoanType.value === '2';
  }

  isValidForm = () => {

    const {
      isValidName, isEmailFormat, isOnlyAlphabetAndSpace, isValidCurrency, isValidAddress
    } = FormHelper.Validation;

    const { name, nric, email, city, monthlyGrossIncome, monthlyNetIncome, nationality, address, address2, companyCity, companyAddress, companyAddress2 } = this.state;

    let isValidForm = this.hasFilledInAllRequiredField() && this.isValidPostCodeAccordingToCountry() &&
      isValidName(name) && this.isValidICNo(nric) && isEmailFormat(email) && isOnlyAlphabetAndSpace(city)
      && isValidCurrency(monthlyGrossIncome) && this.isValidMobileNumber() && !FormHelper.isUSPerson(nationality.value)
      && isValidAddress(address) && isValidAddress(address2)
      && this.isValidCompanyPostCodeAccordingToCountry() && isOnlyAlphabetAndSpace(companyCity) && isValidAddress(companyAddress) && isValidAddress(companyAddress2);

    if (this.isRequiredMonthlyNetIncome())
      isValidForm = isValidForm && isValidCurrency(monthlyNetIncome);

    return isValidForm;
  }

  isValidCalculatorParameters = () => {
    return this.isGrossIncomeMoreMonthlyInstallment() && this.isLoanWithinMinAndMax()
      && this.isAgeEligibleToApplyLoan() === 'OK' && this.isGrossIncomeMoreThanMinimumIncome();
  }

  enableNextButton = () => {
    const { hasVerifyReCaptcha, hasVerifiedOTP } = this.state;

    return this.isValidForm() && this.isValidCalculatorParameters() && hasVerifyReCaptcha && hasVerifiedOTP;
  }

  onNextPressed = async () => {
    const {
      name, gender, email, nationality, bumi, race, address, address2, country, state, foreignState,
      city, postcode, company, occupationSector, occupation, businessSector, natureBusiness,
      monthlyNetIncome, mobile, isAcceptTnc, isAcceptedPDPDConsent, insurancePremium,
      maritalStatus, educationLevel, preferredMailingAddress, companyAddress, companyAddress2,
      companyPostcode, companyCity, companyLocalState, companyForeignState, companyCountry, nonBankDebtAmt
    } = this.state;

    this.props.updateEligibilityStore({
      name, gender, email, nationality, bumi, race, address, address2, country, state, foreignState,
      city, postcode, company, occupationSector, occupation, businessSector, natureBusiness,
      monthlyNetIncome, mobile, isAcceptTnc, isAcceptedPDPDConsent, insurancePremium,
      maritalStatus, educationLevel, preferredMailingAddress, companyAddress, companyAddress2,
      companyPostcode, companyCity, companyLocalState, companyForeignState, companyCountry, nonBankDebtAmt
    })

    this.setState({ isCallingWebservice: true })

    if (!await this.initForm()) {
      this.setState({ isCallingWebservice: false })
      return;
    }

    if (!await this.callCCRISPayload()) {
      this.setState({ isCallingWebservice: false })
      return;
    }

    this.setState({ isCallingWebservice: false })
  }

  callCCRISPayload = async () => {
    try {
      let eformUuid = this.props.webserviceConfig.eformUuid;
      
      if (this.props.calculator.isAipOff) {
        await Webservice.Eform.updateAipOffEformStatus(eformUuid).then(resp => {
          console.log('updateAipOffEformStatus:success:', resp) 
          this.props.history.push({
            pathname: Routes.submitMoreInfo,
            fromVerifyIdentity: true
          })    
        })
      }
      else {
        // let params = PayloadGenerator.genGetSecurityQuestionPayload();
        // console.log("PayloadGenerator.genGetSecurityQuestionPayload()", PayloadGenerator.genGetSecurityQuestionPayload())
        await Webservice.Eform.getSecuriyQuestion(eformUuid).then(resp => {
          console.log('getSecuriyQuestion:success:', resp) 
            if (resp.isAipOff) {
              this.props.updateCalculatorStore({ isAipOff: resp.isAipOff })
              this.props.history.push({
                pathname: Routes.submitMoreInfo,
                fromVerifyIdentity: true
              })   
            }  
            else {
              this.props.history.push(Routes.securityQuestion, { ...resp })  
            }       
        })
      }
    } catch (error) {
      console.log('getSecuriyQuestion:err:', error)
      let message = (error instanceof Error) ? error.message : (typeof error === 'object') ? JSON.stringify(error) : error;
      let errorCode = FormHelper.Getter.getErrorCodeFromMessage(message);
      console.log("errorCode, message::", errorCode, message)
      if (ErrorList[errorCode] === 'CCRIS_DOWN_TIME') {
        console.log("is CCRIS Down...")
        this.props.history.replace(Routes.ending, message)
      }
      else { this.baseErrorHandler(error) }

      return false;
    }

    return true;
  }

  onCalculatorUpdated = () => {
    const {
      loanAmount, selectedTenure, selectedLoanType, monthlyInstallment, interestRate, minAgeEligibility, loanTypes,
      monthlyGrossIncome, nric, selectedEmploymentType
    } = this.state;

    this.props.updateCalculatorStore({
      loanAmount, selectedTenure, selectedLoanType, monthlyInstallment, interestRate, minAgeEligibility, loanTypes, selectedEmploymentType,
      grossMonthlyIncome: monthlyGrossIncome,
      icNo: nric
    })

    this.setState({ showCalculatorModal: false });
  }

  closeFade = () => {
    this.setState({ requestedOTP: false, hasVerifiedOTP: false })
  }

  isValidMobileNumber = () => {
    const { isFromAOP, mobile } = this.state;
    // if from AOP and auto populate, returned mobile number format is +60172358777
    if (isFromAOP && mobile) {
      return true
    }
    // if create an new application, mobile number format to verify is +6017-2358777
    if (!isFromAOP && mobile && FormHelper.Validation.isValidLocalMobileNumber(mobile)) {
      return true
    }
    else {
      return false
    }
  }

  renderTac = () => {
    const { requestedOTP, hasVerifiedOTP, shouldShowLoadingIndicator, verificationCode, mobile } = this.state;
    if (requestedOTP && !hasVerifiedOTP) {
      return (
        <React.Fragment>
          <Col className="mb-1 col-6 verification-col">
            <MobileNumberTac
              tac={verificationCode}
              onClickRequestTac={this.requestTac}
              onChangeTac={this.onVerificationCodeChange}
              shouldShowLoadingIndicator={shouldShowLoadingIndicator && verificationCode.length >= 6}
              loadingIndicatorStyle={styles["tac-loading-indicator"]}
            />
          </Col>
        </React.Fragment>
      );
    } else if (hasVerifiedOTP) {
      return (
        <Col className={`mb-1 col-3 verification-col ${requestedOTP && !hasVerifiedOTP ? "" : "index-0"}`}>
          <p className="verify-code-success">VERIFIED</p>
          <FontAwesomeIcon icon={faCheck} />
        </Col>
      );
    } else {
      return (
        <Col className="mb-1 col-3">
          <Button data-name="requestedOTP" className="proceedButton mt-0" style={{ width: '100%' }}
            onClick={this.isValidMobileNumber() ? this.requestTac : null}>
            Request verification code
          </Button>
        </Col>
      );
    }
  };

  modalTncComponent = () => {
    return (
      <React.Fragment>
        <Modal className="share-permission-modal" isOpen={this.state.showTnCModal} toggle={this.toggleTnCModal}>
          <ModalHeader toggle={this.toggleTnCModal}><b>DECLARATION</b></ModalHeader>
          <ModalBody>
            <Declaration isIslamic={this.isIslamic()} />
          </ModalBody>
          <ModalFooter>
            <Button className="back-btn" data-name="declaration" onClick={this.toggleTnCModal}>BACK</Button>
            <Button color="primary" data-name="declaration" onClick={this.onTnCAgree}>I AGREE</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  };

  modalCalculatorComponent = () => {

    const {
      loanAmount, selectedTenure, selectedLoanType, monthlyInstallment, interestRate,
      monthlyGrossIncome, maxLoanAmount, tenureOptions, minLoanAmount, isCashBackCampaign, installmentBeforeCashBack, installmentAfterCashBack
    } = this.state;

    return (
      <React.Fragment>
        <Modal className="calculator-modal" isOpen={this.state.showCalculatorModal} toggle={this.toogleCalculatorModal}>
          <ModalHeader toggle={this.toogleCalculatorModal}>CHANGE MY DESIRED FINANCING</ModalHeader>
          <ModalBody>
            <Calculator y="920" loanAmount={loanAmount} tenureOptions={tenureOptions}
              selectedTenure={selectedTenure} selectedLoanType={selectedLoanType}
              monthlyInstallment={monthlyInstallment} maxLoanAmount={maxLoanAmount} minLoanAmount={minLoanAmount}
              interestRate={interestRate} grossMonthlyIncome={monthlyGrossIncome} loanTypes={LoanTypeList}
              onMonthlyInstallmentChange={this.onMonthlyInstallmentChange} onLoanAmountChange={this.onLoanAmountChange}
              onGrossIncomeChange={this.onMonthlyGrossIncomeChange} onTenureChange={this.onTenureChange} onLoanTypeChange={this.onLoanTypeChange}
              isCashBackCampaign={isCashBackCampaign}
              installmentBeforeCashBack={installmentBeforeCashBack}
              installmentAfterCashBack={installmentAfterCashBack}
              onInstallmentBeforeCashback={this.onInstallmentBeforeCashback}
              onInstallmentAfterCashback={this.onInstallmentAfterCashback}
              onCashBack={this.onCashBack}
              onTotalCashBack={this.onTotalCashBack}
            />
          </ModalBody>
          <ModalFooter>
            <Button className="back-btn" data-name="calculator" onClick={this.toogleCalculatorModal}>BACK</Button>
            <Button color="primary" data-name="calculator" onClick={this.onCalculatorUpdated}>APPLY CHANGES</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  };

  requestTac = () => {

    this.setState({ requestedOTP: true })

    const { mobile, nric, name, email } = this.state;

    let requestTacPayload = PayloadGenerator.genRequestTacPayloadByMobileNo(mobile, nric, name, email);

    Webservice.Tac.requestTac(requestTacPayload).then(resp => {
      console.log('requestTac:', resp)
      if (!resp.error) {
        this.otpUuid = resp.details.uuid;
      } else {
        this.baseErrorHandler(resp.error)
      }
    }).catch(err => {
      console.log('requestTac:', err);
      this.baseErrorHandler(err);
    })
  }

  validateTac = () => {
    const { verificationCode, mobile, nric, name, email } = this.state;
    let validateTacPayload = PayloadGenerator.genValidateTacPayloadByMobileNo(mobile, nric, name, email, verificationCode, this.otpUuid);

    this.setState({ shouldShowLoadingIndicator: true })

    Webservice.Tac.validateTac(validateTacPayload).then(resp => {
      console.log('validateTac:', resp)

      if (resp.jwt) {
        if (!isValidSession()) {
          console.log("isNotValidSession")
          this.props.updateWebserviceConfig({ jwt: resp.jwt })
        }
        this.setState({ hasVerifiedOTP: true })
      }
      else {
        this.baseErrorHandler(resp.error)
      }

    }).catch(err => {
      console.log('validateTac:', err);
      this.setState({ hasVerifiedOTP: false })
      this.baseErrorHandler(err);
    }).finally(() => {
      this.setState({
        shouldShowLoadingIndicator: false
      })
    })
  }

  onClickCheckStatus = () => {
    this.props.history.replace({
      pathname: Routes.checkstatus
    })
  }

  initForm = async () => {
    try {
      let params = PayloadGenerator.genInitEformPayload();
      const { ecid, xuid, cmsid } = this.props.calculator;

      if (!FormHelper.Validation.isValidEcid(ecid))
        delete params.ecid;

      if (!FormHelper.Validation.isValidDxidOrXuid(xuid))
        delete params.xuid;

      if (!FormHelper.Validation.isValidCmsid(cmsid))
        delete params.cmsid;

      await Webservice.Eform.initForm(params).then(resp => {
        console.log('initEform:success:', resp)
        // if (isAipOff) {
          this.props.updateWebserviceConfig({ jwt: resp.jwt, eformUuid: resp.eformUuid })
        // }
        // else {
        //   let eformUuid = resp.headers.get('location').split('/')[resp.headers.get('location').split('/').length - 1];
        //   console.log('eformUuid:', eformUuid);
        //   this.props.updateWebserviceConfig({ eformUuid })
        // }
      })
    } catch (error) {
      console.log('initEform:err:', error)
      // let message = (error instanceof Error) ? error.message : (typeof error === 'object') ? JSON.stringify(error) : error;
      // let errorCode = FormHelper.Getter.getErrorCodeFromMessage(message);
      // console.log("errorCode, message::", errorCode, message)
      // if (ErrorList[errorCode] === 'COOLDOWN_APPLICATION') {
      //   console.log("is Cannot Re-apply...")	
      //   this.props.history.replace(Routes.ending, message)
      // }
      // else { this.baseErrorHandler(error) }
      this.baseErrorHandler(error, this.onClickCheckStatus)

      return false;
    }

    return true;
  }

  initRPAApplication = async () => {
    try {
      let eformUuid = this.props.webserviceConfig.eformUuid;
      await Webservice.Eform.createRPAApplication(eformUuid).then(resp => {
        console.log('initRPAApplication:resp:', resp)
      })
    } catch (error) {
      console.log('initRPAApplication:err:', error)
      this.baseErrorHandler(error);
      return false;
    }
    return true;
  }

  onEditAddress = () => {
    this.setState({ isRenderEditAddress: true, address: '', address2: '', postcode: '', state: '', city: '' })
  }

  onEditEmail = () => {
    this.setState({ isRenderEditEmail: true, email: '' })
  }

  onClickCancelEditAddress = () => {
    const { address, address2, postcode, city, state } = this.props.eligibility;
    this.setState({ isRenderEditAddress: false, address: address, address2: address2, postcode: postcode, city: city, state })
  }

  onClickCancelEditEmail = () => {
    this.setState({ isRenderEditEmail: false, email: this.props.eligibility.email })
  }

  renderICNumberTextField = () => {
    return (
      <Input
        specialClass="field-input"
        label="NRIC Number"
        name="nric"
        onChange={this.onICNumberChange}
        placeholder="NRIC Number"
        value={this.state.nric}
        isValidInput={this.isValidICNo}
        errText={"Please enter a valid Malaysia IC Number."}
        format="######-##-####"
        mask="_"
        disabled={true}
      />
    )
  }

  renderMaskedIC = () => {
    return (
      <>
        <div className="display-text-label">NRIC Number</div>
        <div className={`form-control field-input display-text ${inputStyles["kyi-input"]}`}>
          {FormHelper.maskICNumber(this.state.nric)}
        </div>
        {this.state.nric && this.showGreenTick()}
      </>
    )
  }

  renderEmailTextField = () => {
    return (
      <>
        {this.state.isRenderEditEmail ? <a href="#" className="textfield-cancel-edit-link" onClick={this.onClickCancelEditEmail}>Cancel Editing Email</a> : null}
        <Input
          specialClass="field-input"
          label="Email"
          name="email"
          onChange={this.onEmailChange}
          placeholder="Email"
          value={this.state.email}
          isValidInput={FormHelper.Validation.isEmailFormat}
          errText="Please enter a correct Email"
          maxLength={data.maxlength.email}
        />
      </>
    )
  }

  renderMaskedEmail = () => {
    return (
      <>
        <div className="display-text-label">Email</div>
        <div className="display-text-edit-link" onClick={this.onEditEmail}><FontAwesomeIcon icon={faPen} /> Edit Email</div>
        <div className={`form-control field-input display-text ${inputStyles["kyi-input"]}`}>
          {FormHelper.maskEmail(this.state.email)}
        </div>
        {this.state.email && this.showGreenTick()}
      </>
    )
  }

  renderMobileNumberTextField = () => {
    return (
      <Input
        type="tel"
        label="Mobile Number"
        specialClass="field-input"
        name="mobile"
        onChange={this.onMobileNumberChange}
        placeholder="Mobile Number"
        value={this.state.mobile}
        disabled={this.state.requestedOTP}
        shouldShowLoadingIndicator={this.state.shouldShowLoadingIndicator}
        maxLength={data.maxlength.mobileNumber}
        isValidInput={FormHelper.Validation.isValidLocalMobileNumber}
        errText={'Please enter a correct mobile number'}
      />
    )
  }

  renderMaskedMobileNumber = () => {
    return (
      <>
        <div className="display-text-label">Mobile Number</div>
        <div className={`form-control field-input display-text ${inputStyles["kyi-input"]}`}>
          {FormHelper.maskMobileNumber(this.state.mobile)}
        </div>
        {this.showGreenTick()}
      </>
    )
  }

  renderAddressTextField = () => {
    return (
      <>
        {this.state.isRenderEditAddress ? <><hr /><a href="#" className="edit-link" onClick={this.onClickCancelEditAddress}>Cancel Editing Address</a></> : null}
        <Row className="my-3 mb-responsive">
          <Col className="mb-4 col-6">
            <Input
              specialClass="field-input"
              label="Home Address Line 1"
              name="address"
              onChange={this.onAddressChange}
              placeholder="Home Address Line 1"
              value={this.state.address}
              maxLength={data.maxlength.addressLine1}
              isValidInput={FormHelper.Validation.isValidAddress}
              errText={`Only # - : ' , . / symbols are allowed`}
            />
          </Col>
          <Col className="mb-4">
            <Input
              specialClass="field-input"
              label="Home Address Line 2"
              name="address2"
              onChange={this.onAddress2Change}
              placeholder="Home Address Line 2"
              value={this.state.address2}
              maxLength={data.maxlength.addressLine2}
              isValidInput={FormHelper.Validation.isValidAddress}
              errText={`Only # - : ' , . / symbols are allowed`}
              optional={this.state.address2 ? false : true}
            />
          </Col>
        </Row>
        <Row className="my-3 mb-responsive">
          <Col className="mb-4">
            <Input
              type={this.state.country && this.state.country.value === 'MY' ? "tel" : "text"}
              specialClass="field-input"
              label="Post Code"
              name="postcode"
              onChange={this.onPostCodeChange}
              placeholder="Post Code"
              value={this.state.postcode}
              isValidInput={this.getPostCodeValidation()}
              maxLength={data.maxlength.postcode}
              errText="Please enter a correct postcode"
              optional={this.isPostCodeOptional()}
            />
          </Col>
          <Col className="mb-4">
            <Input
              specialClass="field-input"
              label="City"
              name="city"
              onChange={this.onCityChange}
              placeholder="City"
              value={this.state.city}
              maxLength={data.maxlength.city}
              isValidInput={FormHelper.Validation.isOnlyAlphabetAndSpace}
              errText="Only alphabet is allowed"
            />
          </Col>
          <Col className="mb-4">
            {this.state.country && this.state.country.value === 'MY' ?
              (<Dropdown
                label="State"
                name="state"
                placeholder="State"
                options={StateList}
                value={this.state.state}
                onChange={this.onLocalStateChange}
              />) :
              (<Input
                specialClass="field-input"
                label="State"
                name="foreignState"
                onChange={this.onForeignStateChange}
                placeholder="State"
                value={this.state.foreignState}
                maxLength={data.maxlength.foreignState}
                isValidInput={FormHelper.Validation.isOnlyAlphabetAndSpace}
                errText="Only alphabet is allowed"
                optional
              />)
            }
          </Col>
          <Col className="mb-4">
            <Dropdown
              label="Country"
              name="country"
              placeholder="Country"
              options={CountriesList}
              value={this.state.country}
              onChange={this.onCountryChange}
            />
          </Col>
        </Row>
      </>
    )
  }

  isCorrectAddress = () => {
    const { address, postcode, city, state, country } = this.props.eligibility;
    return address && postcode && city && state && country
  }

  showGreenTick = () => {
    return (
      <div className={inputStyles["form-tick"]} style={{ right: 30 }}><FontAwesomeIcon icon={faCheck} /></div>
    )
  }

  showTipsMessage = (msg) => {
    return (
      <p className="tips-msg">{msg}</p>
    )
  }

  renderMaskedAddress = () => {
    const { address, address2, postcode, city, state, country } = this.state;
    return (
      <>
        <hr />
        <div className="edit-link" onClick={this.onEditAddress}><FontAwesomeIcon icon={faPen} /> Edit Home Address</div>
        <Row className="my-3 mb-responsive">
          <Col className="mb-4 col-6">
            <div className="display-text-label">Home Address Line 1</div>
            <div className={`form-control field-input display-text ${inputStyles["kyi-input"]}`}>
              {FormHelper.maskAddressLine1(address)}
            </div>
            {address && this.showGreenTick()}
          </Col>
          <Col className="mb-4">
            <div className="display-text-label">Home Address Line 2</div>
            <div className={`form-control field-input display-text ${inputStyles["kyi-input"]}`}>
              {FormHelper.maskAddressLine2(address2)}
            </div>
            {address2 && this.showGreenTick()}
          </Col>
        </Row>
        <Row className="my-3 mb-responsive">
          <Col className="mb-4">
            <div className="display-text-label">Post Code</div>
            <div className={`form-control field-input display-text ${inputStyles["kyi-input"]}`}>
              {FormHelper.maskPostcode(postcode)}
            </div>
            {postcode && this.showGreenTick()}
          </Col>
          <Col className="mb-4">
            <div className="display-text-label">City</div>
            <div className={`form-control field-input display-text ${inputStyles["kyi-input"]}`}>
              {FormHelper.maskCity(city)}
            </div>
            {city && this.showGreenTick()}
          </Col>
          <Col className="mb-4">
            <div className="display-text-label">State</div>
            <div className={`form-control field-input display-text ${inputStyles["kyi-input"]}`}>
              {state.label}
            </div>
            {state && this.showGreenTick()}
          </Col>
          <Col className="mb-4">
            <div className="display-text-label">Country</div>
            <div className={`form-control field-input display-text`}>
              {country && FormHelper.maskCountry(country.label)}
            </div>
            {country && this.showGreenTick()}
          </Col>
        </Row>
      </>
    )
  }

  renderAddressArea = () => {
    if (this.state.isRenderEditAddress) {
      return this.renderAddressTextField();
    }
    else {
      return this.renderAddressTextField();
    }
  }

  renderEmailArea = () => {
    if (this.state.isRenderEditEmail) {
      return this.renderEmailTextField();
    }
    else {
      if (this.state.isFromAOP) {
        if (this.state.email) { return this.renderMaskedEmail(); } // else is correct email
        else { return this.renderEmailTextField(); } // else email is empty
      }
      else { return this.renderEmailTextField(); } // else is not from AOP
    }
  }

  render() {
    const { nric, minLoanAmount, maxLoanAmount, minMonthlyIncome, country, isCallingWebservice } = this.state;
    return (
      <>
        <Header
          version="b"
          currentStage={1}
          progressBarPercentage={0}
        />
        <Section className="verify-identity-section">
          <SlidingErrorAlert
            isShow={!this.isGrossIncomeMoreMonthlyInstallment()}
            message={'Your monthly gross income is less then monthly installment, please reduce your financing amount or increase tenure year.'}
            showDismissButton={false} />

          <SlidingErrorAlert
            isShow={!FormHelper.Validation.isICFormat(nric) && this.isAgeEligibleToApplyLoan() !== 'OK'}
            message={this.isAgeEligibleToApplyLoan()}
            showDismissButton={false} />

          <SlidingErrorAlert
            isShow={!this.isLoanWithinMinAndMax()}
            message={`Your financing amount should within RM${FormHelper.thousandSeparator(minLoanAmount)} and RM${FormHelper.thousandSeparator(maxLoanAmount)}`}
            showDismissButton={false} />

          <SlidingErrorAlert
            isShow={!this.isGrossIncomeMoreThanMinimumIncome()}
            message={`Minimum gross monthly income is RM${FormHelper.thousandSeparator(minMonthlyIncome)}.`}
            showDismissButton={false} />

          <SlidingErrorAlert
            isShow={!this.isValidICNo(nric)}
            message={`Please enter a valid Malaysia IC Number.`}
            showDismissButton={false} />

          <Container>
            <Row>
              <Col>
                <SectionTitle text="BASIC INFORMATION FOR FINANCING ASSESSMENT" />
                <p className="section-description">
                  You are only required to fill in the minimum information below and perform verification{this.props.calculator.isAipOff? null : " to receive your initial financing offer in 10 minutes"}.
              </p>
                {/* <Button className="toggle-calculator-btn" data-name="calculator" onClick={this.toogleCalculatorModal} color="link">Change my desired financing tenure</Button> */}
                {this.modalCalculatorComponent()}
              </Col>
            </Row>
            <Row className="my-3 mb-responsive">
              <Col className="mb-4 col-6">
                <Input
                  specialClass="field-input"
                  label="Full Name (as per NRIC)"
                  name="name"
                  onChange={this.onNameChange}
                  onBlur={this.onNameBlur}
                  placeholder="Full Name (as per NRIC)"
                  value={this.state.name}
                  isValidInput={FormHelper.Validation.isValidName}
                  errText={`Only alphabets and "@ , - , ', / " symbols is allowed.`}
                  maxLength={data.maxlength.name}
                  disabled={this.state.isFromAOP}
                />
              </Col>
              <Col className="mb-4">
                {
                  this.state.isFromAOP ? this.renderMaskedIC() : this.renderICNumberTextField()
                }
              </Col>
              <Col className="mb-4">
                <Dropdown
                  label="Gender"
                  name="gender"
                  placeholder="Gender"
                  options={GenderList}
                  value={this.state.gender}
                  onChange={this.onGenderChange}
                />
              </Col>
            </Row>
            <Row className="my-3 mb-responsive">
              <Col className="mb-4 col-6">
                {this.renderEmailArea()}
              </Col>
              <Col className="mb-4">
                <Dropdown
                  htmlFor="maritalStatus"
                  label="Marital Status"
                  name="maritalStatus"
                  placeholder="Marital Status"
                  options={MaritalStatusList}
                  value={this.state.maritalStatus}
                  onChange={this.onMaritalStatusChange}
                />
              </Col>
              <Col className="mb-4">
                <Dropdown
                  label="Race"
                  name="race"
                  placeholder="Race"
                  options={RaceList}
                  value={this.state.race}
                  onChange={this.onRaceChange}
                />
              </Col>
            </Row>
            <Row className="my-3 mb-responsive">
              <Col className="mb-4 col-6">
                <Dropdown
                  label="Highest Education Level"
                  name="educationLevel"
                  placeholder="Highest Education Level"
                  options={EducationLevelList}
                  value={this.state.educationLevel}
                  onChange={this.onEducationLevelChange}
                />
              </Col>
              <Col className="mb-4">
                <Dropdown
                  label="Nationality"
                  name="nationality"
                  placeholder="Nationality"
                  options={NationalityList}
                  value={this.state.nationality}
                  onChange={this.onNationalityChange}
                  errText={this.state.nationality && FormHelper.isUSPerson(this.state.nationality.value) ? "US person is not allowed to apply" : ''}
                />
              </Col>
            </Row>
            {this.renderAddressArea()}
            <Row className="my-3 mb-responsive">
              <Col>
                Thanks for choosing Alliance Bank again! Kindly note that Notice of Disbursement for current loan applied will be sent to the permanent address, which you have registered with the bank previously.
                For more information, please contact Customer Service at 03-5516 9988.
              </Col>
            </Row>
            <hr />
            <Row className="my-3 mb-responsive">
              <Col className="my-4">
                <Input
                  specialClass="field-input"
                  label="Company Name"
                  name="company"
                  onChange={this.onCompanyChange}
                  placeholder="Company Name"
                  value={this.state.company}
                  maxLength={data.maxlength.companyName}
                />
              </Col>
              <Col className="my-4">
                <Dropdown
                  label="Employment Type"
                  name="selectedEmploymentType"
                  placeholder="Employment Type"
                  options={EmploymentTypeList}
                  value={this.state.selectedEmploymentType}
                  onChange={this.onEmploymentTypeChange}
                  isDisabled={true}
                />
              </Col>
            </Row>
            <Row className="my-3 mb-responsive">
              <Col className="mb-4 col-6">
                <Input
                  specialClass="field-input"
                  label="Company Address Line 1"
                  name="companyAddress"
                  onChange={this.onCompanyAddressChange}
                  placeholder="Company Address Line 1"
                  value={this.state.companyAddress}
                  maxLength={data.maxlength.addressLine1}
                  isValidInput={FormHelper.Validation.isValidAddress}
                  errText={`Only # - : ' , . / symbols are allowed`}
                />
              </Col>
              <Col className="mb-4">
                <Input
                  specialClass="field-input"
                  label="Company Address Line 2"
                  name="companyAddress2"
                  onChange={this.onCompanyAddress2Change}
                  placeholder="Company Address Line 2"
                  value={this.state.companyAddress2}
                  isValidInput={FormHelper.Validation.isValidAddress}
                  errText={`Only # - : ' , . / symbols are allowed`}
                  maxLength={data.maxlength.addressLine2}
                  optional={this.state.companyAddress2 ? false : true}
                />
              </Col>
            </Row>
            <Row className="my-3 mb-responsive">
              <Col className="mb-4">
                <Input
                  type={this.state.companyCountry && this.state.companyCountry.value === 'MY' ? "tel" : "text"}
                  specialClass="field-input"
                  label="Post Code"
                  name="companyPostcode"
                  onChange={this.onCompanyPostcodeChange}
                  placeholder="Post Code"
                  value={this.state.companyPostcode}
                  isValidInput={this.props.getPostCodeValidation}
                  maxLength={data.maxlength.postcode}
                  errText="Please enter a correct postcode"
                  optional={this.props.isPostCodeOptional}
                />
              </Col>
              <Col className="mb-4">
                <Input
                  specialClass="field-input"
                  label="City"
                  name="companyCity"
                  onChange={this.onCompanyCityChange}
                  placeholder="City"
                  value={this.state.companyCity}
                  maxLength={data.maxlength.city}
                  isValidInput={FormHelper.Validation.isOnlyAlphabetAndSpace}
                  errText="Only alphabet is allowed"
                />
              </Col>
              <Col className="mb-4">
                {this.state.companyCountry && this.state.companyCountry.value === 'MY' ?
                  (<Dropdown
                    label="State"
                    name="companyLocalState"
                    placeholder="State"
                    options={StateList}
                    value={this.state.companyLocalState}
                    onChange={this.onCompanyLocalStateChange}
                  />) :
                  (<Input
                    specialClass="field-input"
                    label="State"
                    name="companyForeignState"
                    onChange={this.onCompanyForeignStateChange}
                    placeholder="State"
                    value={this.state.companyForeignState}
                    maxLength={data.maxlength.foreignState}
                    isValidInput={FormHelper.Validation.isOnlyAlphabetAndSpace}
                    errText="Only alphabet is allowed"
                    optional
                  />)
                }
              </Col>
              <Col className="mb-4">
                <Dropdown
                  label="Country"
                  name="companyCountry"
                  placeholder="Country"
                  options={CountriesList}
                  value={this.state.companyCountry}
                  onChange={this.onCompanyCountryChange}
                />
              </Col>
            </Row>
            <Row className="my-3 mb-responsive">
              <Col className="mb-4">
                <Dropdown
                  label="Occupation Sector"
                  name="occupationSector"
                  placeholder="Occupation Sector"
                  options={this.sortOccupationSectorList()}
                  value={this.state.occupationSector}
                  onChange={this.onOccupationSectorChange}
                />
              </Col>
              <Col className="mb-4">
                <Dropdown
                  label="Occupation"
                  name="occupation"
                  placeholder="Occupation"
                  options={this.filterOccupationDropdownBasedOnSector()}
                  value={this.state.occupation}
                  onChange={this.onOccupationChange}
                  isDisabled={!this.state.occupationSector}
                />
              </Col>
            </Row>
            <Row className="my-3 mb-responsive">
              <Col className="mb-4">
                <Dropdown
                  label="Nature of Business Sector"
                  name="businessSector"
                  placeholder="Nature of Business Sector"
                  options={this.sortBusinessSectorList()}
                  value={this.state.businessSector}
                  onChange={this.onBusinessSectorChange}
                />
              </Col>
              <Col className="mb-4">
                <Dropdown
                  label="Nature of Business"
                  name="natureBusiness"
                  placeholder="Nature of Business"
                  options={this.filterBusinessDropdownBasedOnSector()}
                  value={this.state.natureBusiness}
                  onChange={this.onNatureBusinessChange}
                  isDisabled={!this.state.businessSector}
                />
              </Col>
            </Row>
            <Row className="my-3 mb-responsive">
              <Col className="mb-4">
                <Input
                  specialClass="field-input"
                  label="Monthly Gross Income"
                  name="monthlyGrossIncome"
                  onChange={this.onMonthlyGrossIncomeChange}
                  placeholder="Monthly Gross Income"
                  value={this.state.monthlyGrossIncome}
                  maxLength={data.maxlength.monthlyGrossIncome}
                  prefixLabel="RM "
                  isValidInput={FormHelper.Validation.isValidCurrency}
                  errText="Only numeric is allowed"
                  thousandSeparator
                  decimalSeparator={false}
                  disabled={true}
                />
              </Col>
              <Col className="mb-4">
                <Dropdown
                  label="Preferred Mailing Address"
                  name="preferredMailingAddress"
                  placeholder="Preferred Mailing Address"
                  options={PreferredMailingAddressList}
                  value={this.state.preferredMailingAddress}
                  onChange={this.onPreferredMailingAddressChange}
                />
                {/* <a href="/assets/AlliancePersonalLoan-Insurance-PDS.pdf" target="_Blank"><button className="product-disclosure-btn">VIEW PRODUCT DISCLOSURE SHEET</button></a>
              <Dropdown
                label="Do You Want Insurance Protection?"
                name="insurancePremium"
                placeholder="Select yes/no include insurance premium"
                options={InsurancePremium}
                value={this.state.insurancePremium}
                onChange={this.onInsurancePremiumChange}
              /> */}
              </Col>
            </Row>
            {
              this.isRequiredMonthlyNetIncome() ?
                (
                  <Row className="my-3 mb-responsive">
                    <Col className="mb-4">
                      <Input
                        type="tel"
                        specialClass="field-input"
                        label="Monthly Net Income"
                        name="monthlyNetIncome"
                        onChange={this.onMonthlyNetIncomeChange}
                        placeholder="Monthly Net Income"
                        value={this.state.monthlyNetIncome}
                        maxLength={data.maxlength.monthlyNetIncome}
                        prefixLabel="RM "
                        isValidInput={FormHelper.Validation.isValidCurrency}
                        errText="Only numeric is allowed"
                        thousandSeparator
                        decimalSeparator={false}
                      />
                    </Col>
                    <Col className="mb-4"></Col>
                  </Row>) : (null)
            }
            <Row className="mb-responsive">
              <Col className="mb-3 col-6 ">
                <div className="info-alert">
                  <img src={require('../../assets/img/exclamation-circle-info.png')} width="20" style={{ marginRight: 10 }} />
                  <span>You will be notified of the result of your application via your mobile number.</span>
                </div>
              </Col>
            </Row>
            <Row className="my-3 mb-responsive">
              <Col className={`mb-1 col-3 ${
                this.state.requestedOTP && !this.state.hasVerifiedOTP ? "verification-col" : ""
                }`}
              >
                {
                  this.state.isFromAOP ? this.renderMaskedMobileNumber() : this.renderMobileNumberTextField()
                }
              </Col>
              {this.renderTac()}
            </Row>
            {this.state.requestedOTP && !this.state.hasVerifiedOTP ?
              <Row className="my-3 mb-responsive">
                <Col style={{ fontSize: 12 }}>
                  If you didn't receive verification code, click "RESEND CODE" button after 1 minute.
              </Col>
              </Row> : ''}
            <Row className="mt-3">
              <Col className="mb-3">
                <Pdpa onChange={this.onPdpaChange} isAcceptedPDPDConsent={this.state.isAcceptedPDPDConsent} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="mb-3">
                <Nonbankdebt 
                onChange={this.onNonbankdebtChange} 
                hasNonbankdebt={this.state.hasNonbankdebt} 
                nonBankDebtAmt={this.state.nonBankDebtAmt}
                onNonBankDebtAmtChange={this.onNonBankDebtAmtChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-4">
                <TncDeclaration toggle={this.toggleTnCModal} isAcceptTnc={this.state.isAcceptTnc} onChange={this.onTncChange} />
                {this.modalTncComponent()}
                {this.isValidForm() && (
                  <ReCAPTCHA
                    className="my-3"
                    sitekey={Environment.recaptchaKey}
                    onChange={this.captcaOnChange}
                  />
                )}
                <Button id="gtm-basic-info" disabled={!this.enableNextButton() || isCallingWebservice || (isValidSession() && !canApplyLoanApplication())} onClick={this.onNextPressed} className="proceedButton" color="secondary">
                  {(isValidSession() && !canApplyLoanApplication()) ? 'NO ACCESS' : isCallingWebservice ? 'LOADING' : 'PROCEED'}
                </Button>
              </Col>
            </Row>
          </Container>
        </Section>
        <NeedHelp
          name={this.props.eligibility.name ? this.props.eligibility.name : this.state.name ? this.state.name : ''}
          mobile={this.props.eligibility.mobile !== "+60" ? this.props.eligibility.mobile : this.state.mobile ? this.state.mobile : '+60'}
          email={this.props.eligibility.email ? this.props.eligibility.email : this.state.email ? this.state.email : ''}
          currentScreen={PloanScreensList.SCREEN_BASIC_INFO}
        />
        <Footer />
      </>
    );
  }
}

const mapStateToProps = state => ({
  calculator: state.calculator,
  eligibility: state.eligibility,
  webserviceConfig: state.webserviceConfig
});

const mapDispatchToProps = dispatch => ({

  updateCalculatorStore: (info) => {
    dispatch(calculator.updateCalculatorInfo(info))
  },
  clearCalculatorStore: () => {
    dispatch(calculator.clearCalculatorInfo())
  },
  updateEligibilityStore: (info) => {
    dispatch(eligibility.updateEligibilityInfo(info))
  },
  clearEligibilityStore: () => {
    dispatch(eligibility.clearEligibilityInfo())
  },
  updateWebserviceConfig: (info) => {
    dispatch(webserviceConfig.updateWebserviceConfig(info))
  },
  clearWebserviceConfig: () => {
    dispatch(webserviceConfig.clearWebserviceConfig())
  }
});

export default withRefreshHandler(withCustomAlert(connect(mapStateToProps, mapDispatchToProps)(VerifyIdentity)));